import React, {Component} from 'react';
import { connect } from 'react-redux';
import { fetchAllproreview } from '../actions/reviewsubmit';
import ReviewSubmit from '../components/ReviewSubmit';
import {dateFormat} from '../utils/dateFormat';
import {Link} from 'react-router-dom';
import parse from 'html-react-parser';
import {trimString} from '../utils/trimString';

 class ReviewsubmitContainer extends Component {

	componentDidMount() {
		window.scrollTo(0, 0);
		let slug = this.props.slug; 
		this.props.dispatch(fetchAllproreview(slug));
	}
	
    componentDidUpdate(prevProps) {
        let slug =this.props.slug;
        if (slug!== prevProps.slug) {
        this.props.dispatch(fetchAllproreview(slug));
        }
    }

    render(){
		const {reviewsubmit}  = this.props;
        const {isAuthenticated, loading}=this.props.auth;




if (!reviewsubmit) return <div>Loading...</div>;

		return (
        <div className="product-description-review-section section sec-rev mb-xs-20">
            <div className="container ">
                <div className="row">
                  
                    <div className="col">
                        <div className="section-title mb-xs-20">
                          
                        </div>
                    </div>
                   
                </div>     

               
                <div className="row" id="ingredients">

                 {reviewsubmit && reviewsubmit.length === 0? 
                   <div className="col-md-6"><h2>Reviews:</h2>
                        <h4>No review yet.</h4></div>:
                   <div className="col-md-6 mr_btm">


                    {reviewsubmit.slice(0, 3).map(review => ( 
                          <div className="product-review-tab">
                            <ul>
                                <li>
                                    <div className="product-comment" style={{'margin-bottom': '19px'}}>
                                        <div className="product-comment-content">
                                            <div class="ratingfill">
                                            <i className={"fa fa-leaf " + (review.rating < 1 ? 'unselctleaf' : '')}></i>
                                            <i className={"fa fa-leaf " + (review.rating < 2 ? 'unselctleaf' : '')}></i>
                                            <i className={"fa fa-leaf " + (review.rating < 3 ? 'unselctleaf' : '')}></i>
                                            <i className={"fa fa-leaf " + (review.rating < 4 ? 'unselctleaf' : '')}></i>
                                            <i className={"fa fa-leaf " + (review.rating < 5 ? 'unselctleaf' : '')}></i>

                                        <span className="stock available" title="Availability" style={{'color':'#3F5736','text-transform': 'uppercase','font-size:':'16px !important','font-weight':'bold'}}>  <span> &nbsp; {review.headline}</span></span>
                                        </div>
                                       <p className="meta">
                                        <strong>{review.name}</strong> - <span> {dateFormat(review.review_date)}</span>
                                                <div className="description">
                                                    {parse(trimString(review.review,100))}
                                                    <Link to ={'/allreviews/' +this.props.reviewsubmit[0].tof_product.slug}>&nbsp; See more</Link>                                                </div>
                                            </p>
                                        </div>
                                    </div>
                                </li>
                           
                            </ul>
                            
                            
                        </div>))}
                             {reviewsubmit.length>3?
<div className="comment-submit1 rev_btn" style 
= {{'textAlign':'left'}}>
                                    <Link to ={'/allreviews/' +this.props.reviewsubmit[0].tof_product.slug}>
                                        <button class="btn">All Reviews</button></Link>
                                </div>:''}  
                            </div>
                         }

 
                        <div className="col-md-6">
                                {!loading && (
                             isAuthenticated?
                            <ReviewSubmit slug={this.props.slug}/>   : 
               
                           <div className="comment-submit">
                                <h3>Write a Review for this product</h3>
                                    <br/>
                                    <h4>Share your thoughts with other customers</h4>
                                    <br/>
                                     <Link to ={'/login'}>
                                        <button type="submit" className="btn">Write Product Review</button></Link>
                                    </div>
                                 )}
                                 <br/>
                        </div>               
                </div>  
                
            </div>  
        </div>

		)
	}
}



const mapStateToProps = state => {
	return {
		reviewsubmit: state.reviewsubmit,
        auth:state.auth,
	};
}

export default connect(
  mapStateToProps,
)(ReviewsubmitContainer);

