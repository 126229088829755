import React, {Component} from 'react';
import { createReview } from '../actions/reviewsubmit';
import Alert from '../layout/Alert';

import { connect } from 'react-redux';

  const initialState =  {
        rating:5,
        headline: '',
        review:'',
        headlineErrors:'',
        reviewErrors:'',
        ratingErrors:'',
        };

  const Star = ({ selected=false, onClick=f=>f }) =>
  <i className= {(selected) ? "fa fa-leaf" : "fa fa-leaf unselctleaf"} onClick={onClick}></i>
    
  
  class ReviewSubmit extends Component { 
  
  constructor(props) {
    super(props)
    this.state = {
      rating: 5,
      slug:this.props.slug,
    }
    this.change = this.change.bind(this)
  }  
  
  state = initialState;
  
  change(rating) {
      this.setState({rating})
    }
  
  handleInputChange = event => {
     const isCheckbox = event.target.type === "checkbox";
    this.setState({
      [event.target.name]: isCheckbox
      ? event.target.checked
      : event.target.value
    });
  };
   
  validate = () => {
    let headlineErrors = "";
    let reviewErrors = "";
    let ratingErrors = "";

  if (!this.state.review) {
       reviewErrors = "Review cannot be blank";
    }
    if (!this.state.rating) {
       ratingErrors = "Please Select Rating";
    }
   if (!this.state.headline) {
       headlineErrors = "Headline cannot be blank";
    }
  if (headlineErrors || reviewErrors||ratingErrors) {
      this.setState({headlineErrors,reviewErrors,ratingErrors});
      return false;
    }
return true;
  };


   handleSubmit = e => {
    e.preventDefault();
    const isValid = this.validate();
    if (isValid) {
      this.props.onAddReview(this.state);
      this.setState(initialState);
    }
 };

    render(){

    const {rating} = this.state;
  return (
            <div className="product-review-tab">
                <div className="review-form-wrapper">
                    <div className="review-form">
                    <h4>Write a Review for this product</h4>
                       <form onSubmit={ this.handleSubmit }>
                            <Alert  msgdivid="reviewsubmit" />

                            <p>Share your thoughts with other customers</p>

                            <div className="comment-form-rating">
                                <label>Your rating is: </label><span style={{'fontSize':'14px','color':'green','marginLeft':'8px'}}>{this.state.rating}</span>                             
                <div className="ratingfill">
                  {[1,2,3,4,5].map((n, i) =>
                  <Star key={i}
                  selected={i < rating}
                  onClick={() => this.change(i+1)}
                  />
                  )}
                  <div style={{ fontSize:14,color: "red"}}>
                  {this.state.ratingErrors}
                  </div>
                </div>
                               
                  </div>
                <br/>
                  <div className="input-element">
                                <div className="review-comment-form-author">
                                   <input type="text" placeholder="Add Your Headline" name="headline" onChange={ this.handleInputChange } value={ this.state.headline } />
                                    <div style={{ fontSize:14,color: "red"}}>
                                        {this.state.headlineErrors}
                                    </div>
                                </div>  
                                <div className="comment-form-comment">
                                 <textarea name="review" cols="40" rows="8" placeholder="Review Description" onChange={ this.handleInputChange } value={ this.state.review }></textarea>
                                 <div style={{ fontSize:14,color: "red"}}>
                                     {this.state.reviewErrors}
                                  </div>
                                </div>                                                                      
                                 <div className="comment-submit">
                                  <button class="btn">Submit Review</button>
                                </div>
                                <br/><br/>
                            </div>
                        </form>
                </div>
            </div>
        </div> 

    )
}
}









const mapDispatchToProps = dispatch => {
  return {
    onAddReview: post => {

      dispatch(createReview(post));
       
    }

  };
};



export default connect(
  null,
  mapDispatchToProps
)(ReviewSubmit);




