import { RELATED_PRODUCTS,BASE_URL } from './types';
import axios from 'axios';

const apiUrl = BASE_URL+'relatedproduct/';


export const fetchRProducts = (relatedproducts) => {
  return {
    type: RELATED_PRODUCTS,
    relatedproducts
  }
};

export const fetchRelatedpro = (slug) => {
  return (dispatch) => {
    return axios.get(apiUrl+slug)
      .then(response => {
        console.log('&&&&&&&&&&&');
        console.log(response.data);
        dispatch(fetchRProducts(response.data))
      })
      .catch(error => {
        throw(error);
      });
  };
};