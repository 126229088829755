import React, {Component} from 'react';
import Header from '../components/Header';
import AddressEdit from '../components/AddressEdit';

import Accountsidebar from '../components/Accountsidebar';
import {Link} from 'react-router-dom';
import Footer from '../components/Footer';
import { fetchAlladdress } from '../actions/address';
import {connect } from 'react-redux'

class AddressEditContainer extends Component {


    constructor() {
        super();       
    }

    componentDidMount() {
        window.scrollTo(0, 0);        
        this.props.dispatch(fetchAlladdress());
    }
 
    render(){
    const {address}  = this.props;
    if (!address) return <div>Loading...</div>;
        return (     
        <div id="main-wrapper">
        <Header/>
          <div className="page-banner-section section bg-gray" style={{'padding': '10px 0 20px'}}>
            <div className="container">
                <div className="row">
                    <div className="col">
                        
                        <div className="page-banner text-center">
                            <h1>My Account</h1>
                            <ul className="page-breadcrumb">
                                <li><a href="/">Home</a></li>
                                <li>Address Book</li>
                            </ul>
                        </div>
                        
                    </div>
                </div>
            </div>
        </div>
        <div class="my-account-section section pt-100 pt-lg-80 pt-md-70 pt-sm-60 pt-xs-50  pb-100 pb-lg-80 pb-md-70 pb-sm-60 pb-xs-50">
            <div class="container">
                <div class="row">
                    <div class="col-12">
                        <div class="row">  
                            <Accountsidebar/>   
                             <div className="col-lg-9 col-12">
                                <div className="tab-content" id="myaccountContent">
                                  <div className="tab-pane fade show active" id="address-edit" role="tabpanel">
                                    <div className="myaccount-contentab" >
                                      <h3>Address Book</h3>
                                      <div style={{'textAlign':'right'}}>
                                       <Link exact to="/add/addressbook" ><button className="place-order btn btn-lg btn-round">Add Address</button></Link>
                                      </div>
                                      {address===''?<center>
                                        <br/>
                                        <br/>
                                          <h4>You currently have no address details saved. You can add or delete an address at any time.</h4>
                                        <br/>
                                        <br/>

                                        <Link to ='/shop' >
                                            <a href="/shop" class="btn">CONTINUE SHOPPING</a><br/>
                                        </Link>
                                         <br/>
                                         <br/>
                                       </center>:
                                       <AddressEdit address={address}  />
                                      }

                               
                              </div>
                                          </div>
                                    </div>
                              </div>


    
                         </div>
                    </div> 
                </div>           
            </div>
        </div>
    <Footer/>
</div>



        )
    }
}
const mapStateToProps = state => {
  return {
    address: state.address.address,
    auth: state.auth

    
  };
};


export default connect(
  mapStateToProps,
  null
)(AddressEditContainer);