import React, { Component } from 'react'
import {Link} from 'react-router-dom';
import {connect} from 'react-redux';
import { deleteCart } from '../actions/cart';
import { quantitychangeCart } from '../actions/cart';
import { IMG_BASE_URL } from '../actions/types';
import {Currency} from '../utils/currency';


 
class Cart extends Component {

    deletetoCart(post) {
      this.props.onDeletetocart(post);
    }

  changequantity(id,flag){
    var quandata = [];
    quandata.push(id);     
    quandata.push(flag); 
    this.props.onchangequantitycart(quandata);
  }

    render(){
    return(
        <tbody>
        {this.props.cartproduct.map((post) => 
          {
                   
  return(
        <tr>
        <td className="pro-thumbnail">
<Link to ={'/product/' +post.slug} >
<img src={IMG_BASE_URL+'products/'+post.feature_image} alt=""/>
</Link>
            
            </td>
            <td className="pro-title" style={{'textAlign':'left'}}> <Link to ={'/product/' +post.slug} >{post.title}</Link>            <div style={{'clear':'both','height':'2px'}}></div>
            
            
{post.sizes=== null ||post.sizes==='undefined'||post.sizes===''?'': <div style={{'clear':'both','height':'2px'}}>Size : {post.sizes}</div>}
           
            </td>
            <td className="pro-subtotal"><span style={{'whiteSpace':'nowrap'}}>{Currency}{post.tof_product.Price}</span></td>
            <td className="pro-quantity">           
       
                
 <div className="pro-qty ">
                                <button class="tblprodqnty" onClick={() => this.changequantity(post.id,'minus')}>-</button>
                                <input type="readonly" value={post.quantity}/>
                                <button className="tblprodqntyplus" onClick={() => this.changequantity(post.id,'add')}>+</button></div>



            </td>
            <td className="pro-subtotal"><span style={{'whiteSpace':'nowrap'}}>{Currency}{parseFloat(post.tof_product.Price*post.quantity).toFixed(2)}</span></td>
            <td className="pro-remove">
   <i className="fa fa-trash-o" style={{cursor: 'pointer'}} onClick={() => this.deletetoCart(post.id)} ></i>




            </td>
        </tr>
        ) })} 
           </tbody>                       

        )
   } 
}
const mapDispatchToProps = dispatch => {
  return {
    onDeletetocart: post => {
     dispatch(deleteCart(post));
    },
    onchangequantitycart: quandata => {
      dispatch(quantitychangeCart(quandata));
    },  
  };
};

const mapStateToProps = state => {
  return {
    cartdelete:state.cartdelete
 
};
};


export default connect(
mapStateToProps,
mapDispatchToProps
)(Cart);

