import React, {Component} from 'react';
import { fetchAllOHistory } from '../actions/orderdetail';
import { connect } from 'react-redux';
import {Link} from 'react-router-dom';
import {Currency} from '../utils/currency';
import { IMG_BASE_URL } from '../actions/types';
import { Router } from 'react-router';
import {dateFormat} from '../utils/dateFormat';

 export default class Orderdetail extends Component {
	 
  render() {

		return (
			<div>
	{this.props.orderdetail.map(item => (

			<div style={{'paddingLeft':'30px'}}> 
				<h3 style={{'marginBottom':'20px','textAlign':'left','marginLeft':'-13px'}}>Order Details of {item.order_number} </h3>
					<div className="row">
						<div class="col-sm-12 rounded border border-secondry historytblnew" >
		                    <div class="accountbdr">
								<div class="myaccount-table table-responsive text-center">
									<span className="headingtxt pull-left orderhishead" style={{'fontWeight':'400'}}>Order Number : {item.order_number}
									<span class="pull-right_account ordertotl orderhishead" style={{'fontWeight':'400'}}>Order Total : {Currency} {parseFloat(item.net_amount).toFixed(2)}</span>
									<span classname="pull-right_account ordertotl orderhishead" style={{'lineHeight':'20px','fontWeight':'400'}}>{dateFormat(item.booking_date)}</span></span>
								</div>
							</div>
						</div>
						{item.tof_order_items.map(item => (					
						
							<div className="col-sm-12 border border-secondry" style={{'backgroundColor':'#fff','border':'1px solid','padding':'10px'}}>
								<div className="col-xs-12 orconfprod">
								   <div className="col-md-3 col-sm-6 col-xs-12 orconfprod1">
									 <Link to ={'/product/' +item.tof_product.slug} className="prodimgsrc" >
										<img src={IMG_BASE_URL+'products/'+item.feature_image} alt=""/>
										</Link>
								   </div>
								   <div className="col-md-4 col-sm-6 col-xs-12 orconfprod2">
									  <h2 style={{'textAlign':'left','whiteSpace':'normal'}}>
										<Link to ={'/product/' +item.tof_product.slug}>
											{item.tof_product.name}										
										</Link>
									  </h2>
									  <p></p>
									  <p style={{'textAlign':'left'}}><span><strong>Qty:</strong> {item.quantities}</span></p>	
									  <p style={{'textAlign':'left'}}><span><strong>Status:</strong> {item.enumstatus}</span></p>
									  <p style={{'textAlign':'left'}}><span><strong>Price: </strong>{Currency} 
											{parseFloat(item.product_price).toFixed(2)}								
									  </span></p>									 
									{item.sizes=== null ||item.sizes=='undefined'||item.sizes==''?'':<p style={{'textAlign':'left'}}><span><strong>Size:</strong> {item.sizes}</span></p>}
								   </div>
								   <div className="col-md-5 col-sm-6 col-xs-12 orconfprod3">
									  <p></p>
									  <p></p>
									  <p><span className="totcost" style={{'fontSize':'14px'}}> 
	{Currency}{parseFloat(item.quantities * parseFloat(item.product_price)).toFixed(2)}

	</span></p>
								   </div>
								</div>
							</div>
						))
						}
						
					</div>
									
					<div className="row">
						<div className="col-sm-12 border border-secondry " style={{'backgroundColor':'#fff','border':'1px solid','padding':'25px 15px 25px 15px','marginTop':'25px','color':'#000000'}}>
							 <div className="row">
								<div className="col-md-4 col-sm-6 col-12" style={{'marginBottom':'25px','fontWeight':'400','textAlign':'left'}}>
								<address className="addhgt" style={{'border':'0px','border':'1px solid rgb(204, 204, 204)','whiteSpace':'pre-wrap'}}>
								   <h4 className="ship_head" style={{'marginBottom':'5px','color':'#ffffff','paddingLeft':'5px','background':'rgb(1, 65, 49)','textAlign':'center'}}>Shipping Address</h4>
								   <div style={{'paddingLeft':'5px'}}>
								   <span style={{'fontSize':'18px'}}>{item.shipping.name}</span>
								   <br/><span style={{'fontSize':'15px'}}>{item.shipping.address}</span>
								   <br/><span style={{'fontSize':'15px'}}>{item.shipping.city}-{item.shipping.zip}</span>
								   <br/><span style={{'fontSize':'15px'}}>{item.shipping.state}</span>
								   {/*<br/><span style={{'fontSize':'15px'}}>{item.shipping.country}</span>*/}
									<br/><span style={{'fontSize':'15px'}}>PH: {item.shipping.phone}</span>
									</div>
									</address>
								</div>
								<div className="col-md-4 col-sm-6 col-12 pdnglf" style={{'marginBottom':'25px','fontWeight':'400','textAlign':'left'}}>
								   <address className="addhgt" style={{'border':'0px','border':'1px solid rgb(204, 204, 204)','whiteSpace':'pre-wrap'}}>
								   <h4 className="ship_head" style={{'marginBottom':'5px','color':'#ffffff','paddingLeft':'5px','background':'rgb(1, 65, 49)','textAlign':'center'}}>Billing Address</h4>
								   <div style={{'paddingLeft':'5px'}}>
								   <span style={{'fontSize':'18px'}}>{item.billing.name}</span>
								   <br/><span style={{'fontSize':'15px'}}>{item.billing.address}</span>
								   <br/><span style={{'fontSize':'15px'}}>{item.billing.city}-{item.billing.zip}</span>
								   <br/><span style={{'fontSize':'15px'}}>{item.billing.state}</span>
								   {/*<br/><span style={{'fontSize':'15px'}}>{item.billing.country}</span>*/}
								   <br/><span style={{'fontSize':'15px'}}>PH: {item.billing.phone}</span>
								   </div>
								   </address>
								</div>
					<div className="col-md-4 col-sm-6 col-12 pdnglf" style={{'marginBottom':'25px','fontWeight':'400','textAlign':'left'}}>
					<address className="addhgt" style={{'border':'0px','border':'1px solid rgb(204, 204, 204)','whiteSpace':'pre-wrap'}}>
					<h4 className="ship_head" style={{'marginBottom':'2px','color':'#ffffff','paddingLeft':'5px','background':'rgb(1, 65, 49)','textAlign':'center'}}>Payment Summary</h4>
						<span style={{'lineHeight':'30px','fontSize':'15px','paddingLeft':'5px'}}>Price : </span>
						<span style={{'lineHeight':'30px','fontSize':'15px','float':'right','paddingLeft':'5px','paddingRight':'5px'}}>{Currency}{parseFloat(item.pay_amount).toFixed(2)}</span>	
						<br/>

								   
								   <span style={{'lineHeight':'30px','fontSize':'15px','paddingLeft':'5px'}}>Shipping : </span>
								   <span style={{'lineHeight':'30px','fontSize':'15px','float':'right','paddingLeft':'5px','paddingRight':'5px'}}>{Currency}{parseFloat(item.total_shipping).toFixed(2)}</span>

								   {item.total_discount === 0?'':<span	><br/>
										<span style={{'lineHeight':'30px','fontSize':'15px','paddingLeft':'5px'}}>Coupon Applied ({item.coupon_code}): </span>
										<span style={{'lineHeight':'30px','fontSize':'15px','float':'right','paddingLeft':'5px','paddingRight':'5px'}}>{Currency}{parseFloat(item.total_discount).toFixed(2)}</span></span>}
								   							
								   <br/>
								   <span style={{'lineHeight':'30px','fontSize':'15px','paddingLeft':'5px'}}>Payment Method : </span>
									<span style={{'lineHeight':'30px','fontSize':'15px','float':'right','paddingLeft':'5px','paddingRight':'5px'}}>{item.method ==='COD'?'Cash On Delivery':item.method}
								</span><br/>
								<span style={{'lineHeight':'30px','fontWeight':'600','fontSize':'18px','marginTop':'25px','paddingLeft':'5px'}}>Order Total :	</span>
								   <span style={{'lineHeight':'30px','fontWeight':'600','fontSize':'18px','paddingLeft':'5px','float':'right','paddingRight':'5px'}} className="totalforads">{Currency}{parseFloat(item.net_amount).toFixed(2)}</span>
								   <br/>

									

								   <span style={{'fontSize':'15px','paddingLeft':'5px'}}>&nbsp;</span>
								   <br/><span style={{'fontSize':'15px','paddingLeft':'5px'}}>&nbsp;</span>
								   <br/><span style={{'fontSize':'15px','paddingLeft':'5px','marginBottom':'25px'}}>&nbsp;</span>
								   <br/><span style={{'fontSize':'15px','paddingLeft':'5px'}}>&nbsp;</span>
								   <br/>
								</address>								   
								</div>
							 </div>
						</div>
					</div>
				</div>
				))}	
			</div>
		)
	}
 }
