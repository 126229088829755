import React, {Component} from 'react';
import { Link,NavLink,withRouter } from 'react-router-dom'
import { logout } from '../actions/auth';
import { connect } from 'react-redux';
var userurls = ["order-history","review","wishlist","addressbook"];
class Accountsidebar extends Component {


render(){
     return (
			<div className="col-lg-3 col-12"  >
				<div className="myaccount-tab-menuab nav" role="tablist" id="myinfoaccounttab">
				    <Link exact to="/dashboard" >Dashboard</Link>
				    <Link exact to="/account-info" >My Info</Link>
				    <Link exact to="/order-history" >Order History</Link>
					<Link exact to="/addressbook" >Address Book</Link>
					<Link exact to="/review" >My Reviews</Link>
					<Link exact to="/wishlist" >My Wishlist</Link>
					<Link exact to="/change-password" >Change Password</Link>
					<Link  onClick={() => this.props.logout()} >Logout</Link>
				</div>
			</div>
		)
	}
}


const mapStateToProps = state => ({
  auth: state.auth
});


export default connect(
  mapStateToProps,
  { logout }
)(Accountsidebar);
