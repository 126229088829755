import React from 'react';


import {Link} from 'react-router-dom';
import { IMG_BASE_URL } from '../actions/types';


export default ({ post: {name,id,image,slug } }) => {
return (

<div className="col-lg-6 col-md-6 col-sm-6">
<div className="single-product mb-30">
<div className="product-img">
<Link to ={'/solution/' +slug} >
<img src={IMG_BASE_URL+'category/'+image} alt=""/>
</Link>
</div>
<div className="product-content">
{/*<h3><Link to ={'/solution/' +slug} >{name}</Link></h3>*/}
<Link to ={'/solution/' +slug} className="prod-title">SHOP NOW</Link>
</div>
</div>
</div>




)
}
