import React, {Component} from 'react';
import { fetchAllTPosts } from '../actions/fetchTestimonial';
import Carousel from 'react-bootstrap/Carousel'
import { connect } from 'react-redux';
import OurSolutionsCarousel from '../components/OurSolutionsCarousel';
import Slider from "react-slick";
import {NavLink } from 'react-router-dom'



class OurSolutionsCarouselContainer extends Component {


componentDidMount() {
  this.props.dispatch(fetchAllTPosts());



}


render(){


const {fetchtestimonial}  = this.props;


const settings = {
  dots: true,
  infinite: true,
  slidesToShow: 3,
  slidesToScroll: 1,
  autoplay: true,
  speed:6000,
  autoplaySpeed: 6000,
  cssEase: "linear",
  pauseOnHover:true,
  draggable:true,

};



return (
<div>



<div className="product-section section mt-5 pt-md-65 pt-sm-60 pb-20 pb-lg-40 pb-md-40 pb-sm-30 expertviewbg" id="cktestimonial">
<div className="container ckdata">
<div className="row">

<div className="col-md-3 text-center col-6" > 
<p><img src="/images/category_images/Shampoo.jpg" alt="" title=""/>
<span className="imagespan">HairCare</span>
</p>

</div>

<div className="col-md-3 text-center col-6" > 
<p><img src="/images/category_images/Sanitizer.jpg" alt="" title=""/>
<span className="imagespan">sanitizer</span>
</p>
</div>

<div className="col-md-3 text-center col-6" > 
<p><img src="/images/category_images/Handwash.jpg" alt="" title=""/>
<span className="imagespan">handwash</span>
</p>
</div>

<div className="col-md-3 text-center col-6" > 
<p><img src="/images/category_images/Handcream.jpg" alt="" title=""/>
<span className="imagespan">hand creams</span>
</p>
</div>


</div>
</div>
</div>




<div className="product-section section pt-md-65 pt-sm-60 pt-xs-45 pb-20 pb-lg-40 pb-md-40 pb-sm-30 pb-xs-50 expertviewbg desktop-view" id="cktestimonial">
<div className="container">
<div className="row">
<div className="col">
<div className="section-title mb-3 mb-xs-2 text-center">
<h2 className="leafbg" style={{'fontWeight':'600','color':'#db9c28'}}>OUR 100% PLANT-BASED RANGES</h2>

</div>
</div>

</div>

<div>
<Slider {...settings} className="oursolutionslick mt-2" >


<div>
<NavLink exact to="/solution/plant-based-biotin" >
          <img
      className="d-block w-30 "
      src="/images/range-1.jpg" 
      alt="First slide"
    />
    <h4 className="text-center text-uppercase mt-2">  PLANT-BASED BIOTIN</h4>
</NavLink>
   
          </div>
<div>

<NavLink exact to="/solution/anti-hairfall" >
<img
      className="d-block w-30 "
      src="/images/range-2.jpg" 
      alt="First slide"
      
    />
    <h4 className="text-center text-uppercase mt-2">ANTI HAIRFALL</h4>
    </NavLink>

          </div>
          <div>

<NavLink exact to="/solution/turmeric-and-bamboo-charcoal">

          <img
      className="d-block w-30 "
      src="/images/range-3.jpg" 
      alt="First slide"
    />
    <h4 className="text-center text-uppercase mt-2">TURMERIC & BAMBOO CHARCOAL</h4>

    </NavLink>

          </div>
          <div>

<NavLink exact to="/solution/anti-dandruff">

          <img
      className="d-block w-30 "
      src="/images/range-4.jpg" 
      alt="First slide"
    />
    <h4 className="text-center text-uppercase mt-2">ANTI DANDRUFF</h4>

</NavLink>

          </div>
       
        </Slider>
      </div>



</div>
</div>


<div className="product-section section pt-md-65 pt-sm-60 pt-xs-30 pb-20 pb-lg-40 pb-md-40 pb-sm-30 expertviewbg mobile-view " id="cktestimonial">
<div className="container">
<div className="row">
<div className="col">
<div className="section-title mb-3 mb-xs-2 text-center">
<h2 className="leafbg" style={{'fontWeight':'600','color':'#db9c28'}}>OUR 100% PLANT-BASED RANGES</h2>

</div>
</div>

</div>

<div className="row">
<div className="col">
<Carousel>
<Carousel.Item>

<NavLink exact to="/solution/plant-based-biotin">
<img className="d-block w-40 slideimgtest" src="/images/range-1.jpg" alt="First slide" width="90%"/>

</NavLink>
</Carousel.Item>
<Carousel.Item>
<NavLink exact to="/solution/anti-hairfall">
<img className="d-block w-40 slideimgtest" src="/images/range-2.jpg" alt="First slide" />

</NavLink>
</Carousel.Item>
<Carousel.Item>
<NavLink exact to="/solution/turmeric-and-bamboo-charcoal">
<img className="d-block w-40 slideimgtest" src="/images/range-3.jpg" alt="First slide" />

</NavLink>
</Carousel.Item>
<Carousel.Item>
<NavLink exact to="/solution/anti-dandruff">
<img className="d-block w-40 slideimgtest" src="/images/range-4.jpg" alt="First slide" />
</NavLink>
</Carousel.Item>

</Carousel>
</div>
</div>
</div>
</div>



<div className="product-section section pt-md-65 pt-sm-60 pt-xs-45 pb-20 pb-lg-40 pb-md-40 pb-sm-30 pb-xs-50 expertviewbg mb-custom mobile-tablet" id="cktestimonial">
<div className="container">
<div className="row">
<div className="col">
<div className="section-title mb-3 mb-xs-2 text-center">
<h2 className="leafbg" style={{'fontWeight':'600','color':'#db9c28'}}>OUR 100% PLANT-BASED RANGES</h2>

</div>
</div>

</div>

<div className="row">
<div className="col">
<Carousel>
<Carousel.Item>


<img className="d-block w-50 slideimgtest" src="/images/range-1.jpg" alt="First slide"/>
<img className="d-block w-50 slideimgtest" src="/images/range-2.jpg" alt="First slide"/>


</Carousel.Item>
<Carousel.Item>

<img className="d-block w-50 slideimgtest" src="/images/range-3.jpg" alt="First slide"/>
<img className="d-block w-50 slideimgtest" src="/images/range-4.jpg" alt="First slide"/>

</Carousel.Item>


</Carousel>
</div>
</div>
</div>
</div>




</div>
);
}
}


const mapStateToProps = state => {
return {
fetchtestimonial: state.fetchtestimonial
};
};


export default connect(
mapStateToProps,
null
)(OurSolutionsCarouselContainer);