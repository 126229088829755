import React from 'react';
import {Link} from 'react-router-dom';
import { IMG_BASE_URL } from '../actions/types';
import {trimString} from '../utils/trimString';



export default ({ post: {name,description, id,image,slug } }) => {

  

return (
<div>





<Link to ={'/ingredient-product/' +slug}>

<div className="product-img shop-product-list ingredient">
 <img src={IMG_BASE_URL+'ingredients/'+image} alt=""/> 

<span className="descount-sticker" style={{'display':'none'}}>-10%</span>
<span className="sticker" style={{'display':'none'}}>New</span>
</div>
<div className="product-content">
<h3 style={{'margin':'0 0 5px 0'}}>{name}</h3>
<div className="rattingbtn">
<p>{trimString(description,90)}</p> 
</div>
<p className="price">

<Link to ={'/ingredient-product/' +slug} className="descount-sticker_btn">Related Products</Link>

</p>
</div>
</Link>
</div>





)
    }














