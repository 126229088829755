import React, { Component } from 'react'
import Header from '../components/Header';
import Footer from '../components/Footer';
import {connect} from 'react-redux';
import { createstatus } from '../actions/orderstatus';
import { fetchAllOrder } from '../actions/orderstatus';
import { withRouter,Link } from "react-router-dom";
import Alert from '../layout/Alert';

const initialState =  {
    enumstatus: '',
    reason:'',
    reasonErrors:'',
	id:''
  };
  
class Ordercancel extends Component {
	state = initialState; 
  
	validate = () => {
		let reasonErrors = "";
		if (!this.state.reason) {
			reasonErrors = "Please select Cancel reason";
		}
		if (reasonErrors) {
			this.setState({reasonErrors});
			return false;
		}
		return true;
	};
 


	constructor(props) {
		super(props);
		this.state = {
			reason: "",
			enumstatus:"Canceled",
			id:this.props.id
		};
		this.handleDropdownChange = this.handleDropdownChange.bind(this);
	}

    handleInputChange = event => {
		const isCheckbox = event.target.type === "checkbox";
		this.setState({
		  [event.target.name]: isCheckbox ? event.target.checked: event.target.value
		});
	}; 
	
    handleDropdownChange(e) {
		this.setState({ reason: e.target.value });
	}
	
		handleSubmit = e => {
		e.preventDefault();
		const isValid = this.validate();
		if (isValid) {  
		
		
			this.props.Statuscheck(this.state);
			this.setState(initialState);
			//window.location.href = "/order-history";		  
		}
	};
	
	

   render(){

   
 
   
    return (
    <div className="col-md-4 col-sm-4 col-12" style={{'display':'inline-block','float':'left','paddingTop':'16px'}}>
     <Alert/>
 <span style={{'line-height':'18px','font-weight':'400','color':'#000000','font-size':'18px'}}>Reasons for Cancel:</span>

        {this.props.orderstatus.map((item) => 
             <div class="">
  
                <div class="login-submit" style={{'textAlign':'center'}}>
               
                      <select   class="form-control" onChange={this.handleDropdownChange}  >
                          <option value = "">Select Cancellation Reason</option>
                          <option value = "Order Created by Mistake">Order Created by Mistake</option>
                          <option value = "Item(s) Would Not Arrive on Time">Item(s) Would Not Arrive on Time</option>
                          <option value = "Shipping Cost Too High">Shipping Cost Too High</option>
                          <option value = "Item Price Too High">Item Price Too High</option>
                          <option value = "Found Cheaper Somewhere Else">Found Cheaper Somewhere Else</option>
                          <option value = "Need to Change Shipping Address">Need to Change Shipping Address</option>
                          <option value = "Need to Change Shipping Speed">Need to Change Shipping Speed</option>
                          <option value = "Need to Change Billing Address">Need to Change Billing Address</option>
                          <option value = "Need to Change Payment Method">Need to Change Payment Method</option>
                          <option value = "other">Other</option>
                      </select>
                      <div style={{ fontSize:14,color: "red"}}>{this.state.reasonErrors}</div>
                        <br/>                     
                         <form action="/#" onSubmit={ this.handleSubmit }>
                              <input type="hidden" onChange={ this.handleInputChange } value={ this.state.reason }  name="reason"/>
                              <input type="hidden" onChange={ this.handleInputChange } value={this.state.enumstatus} name="enumstatus"/>

                                <div class="col-12 mb-30" style={{'paddingLeft':'0px'}}>
                                      <div class="login-submit" style={{'textAlign':'left'}}>
                               <button class="btn"><span>Save Changes</span></button>
                            </div>
                         </div>
                      </form>
                      </div>
                  </div>                           
                )} 

      </div>     
    )
    }
}

const mapDispatchToProps = dispatch => {
  return {
    Statuscheck: post => {
     dispatch(createstatus(post));
    }
  };
};

const mapStateToProps = state => {
  return {
    createstatus: state.createstatus
  };
};


export default connect(
mapStateToProps,
mapDispatchToProps
)(Ordercancel);







                
                                                  
                               
