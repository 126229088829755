import React, {Component} from 'react';
import Header from '../components/Header';
import { fetchAllOHistory } from '../actions/orderhistory';
import { connect } from 'react-redux';
import Orderhistory from '../components/Orderhistory';
import Accountsidebar from '../components/Accountsidebar';
import Footer from '../components/Footer';
import {Link} from 'react-router-dom';
import {dateFormat} from '../utils/dateFormat';
import {Currency} from '../utils/currency';
 class OrderhistoryContainer extends Component {
    componentDidMount() {
    window.scrollTo(0, 0);
    this.props.dispatch(fetchAllOHistory());
}

    render(){
           const {orderhistory}  = this.props.orderhistory;
           const {loading}  = this.props.orderhistory;


if (loading) {
return  <div id="main-wrapper"><Header/>
<div className="product-section section pt-100 pt-lg-40 pt-md-65 pt-sm-60 pt-xs-45 pb-20 pb-lg-40 pb-md-40 pb-sm-30 pb-xs-20 oursolutionsec">
<div className="container">
<div className="row loaderclass" ><img src="/images/loader.gif" alt="loading" style={{'width' : '200px', 'height' : '200px', 'margin' : '0px auto'}} />
<p style={{'width' : '100%', 'verticalAlign' : 'middle', 'textAlign' : 'center', 'padding' : '0px'}}>
Loading in Progress...
</p>
</div>
</div>
</div><Footer/>
</div>;
    }

        return (

        <div id="main-wrapper">
        <Header/>
           <div className="page-banner-section section bg-gray" style={{'padding': '10px 0 20px'}}>
            <div className="container">
                <div className="row">
                    <div className="col">
                        
                        <div className="page-banner text-center">
                            <h1>My Account</h1>
                            <ul className="page-breadcrumb">
                                <li><a href="/">Home</a></li>
                                <li>Order History</li>
                            </ul>
                        </div>
                        
                    </div>
                </div>
            </div>
        </div>
        <div class="my-account-section section pt-100 pt-lg-80 pt-md-70 pt-sm-60 pt-xs-50  pb-100 pb-lg-80 pb-md-70 pb-sm-60 pb-xs-50">
            <div class="container">
                <div class="row">
                    <div class="col-12">
                        <div class="row">  
                          <Accountsidebar/>   
                          <div class="col-lg-9 col-12" style={{'padding-right':'0px'}}>
                                <div class="tab-content" id="myaccountContent">
                                    {orderhistory===''?<center>
                                        <br/>
                                        <br/>
                                        <h4>No purchases made yet.</h4>
                                        <br/>
                                        <br/>
           <Link to ='/shop' class="btn">CONTINUE SHOPPING</Link><br/>
                                      
                                       <br/>
                                       <br/>
                                    </center>:
                                    <div class="tab-pane fade show active" id="orders" role="tabpanel">
                                        <div class="myaccount-contentab">
                                            <h3>Orders</h3>
    
                                            <div class="myaccount-table table-responsive text-center">
                                                

                    {orderhistory.map((post) => 

                        <div class="row historytbl">
                        <div class="col-sm-12 rounded border border-secondry historytblnew" >
                        <div class="accountbdr">
                        <div class="myaccount-table table-responsive text-center">
                        <span className="headingtxt pull-left orderhishead" style={{'fontWeight':'400'}}>Order Number : {post.order_number}
                        <span class="pull-right_account ordertotl orderhishead" style={{'fontWeight':'400'}}>Order Total : {Currency} {parseFloat(post.net_amount).toFixed(2)}</span>
                        <span class="pull-right_account ordertotl orderhishead" style={{'fontWeight':'400'}}>Payment Method : {post.method ==='COD'?'Cash On Delivery':post.method}</span>                        
                        <span classname="pull-right_account ordertotl orderhishead" style={{'lineHeight':'20px','fontWeight':'400'}}>{dateFormat(post.booking_date)}</span></span>
                        <br />
                        <br/>
                        <br />
                        <hr class="hrsec" />
                        <table cellspacing="0" cellpadding="0" border="0" class="table tablebordered"> 
                            <tbody>                                                     
                                {post.tof_order_items.map(postt => {
                                    return (                                                                
                                        <Orderhistory post={ postt }  key={ postt.id } />
                                    );
                                })} 
                                <tr>
                                    
                                    <td class="tdimgdatabtns" valign="left" align="center" colspan="3">
                                   
                                    <Link to ={'/your-order/' +post.order_number} className="descount-sticker_btn" >
            View Details</Link>

                                    </td>
                                    
                                </tr>
                            </tbody>
                        </table>
                        </div>
                        </div>
                        </div>
                        </div>                                      

                    )}
                                            </div>
                                        </div>
                                    </div>}
                                </div>
                            </div>                           
                        </div>    
                    </div>                    
                </div> 
            </div>           
        </div>
        

            <Footer/>
        </div>

     )
    }
}
const mapStateToProps = state => {
  return {
    orderhistory: state.orderhistory,
    auth: state.auth
  };
};


export default connect(
  mapStateToProps,
  null
)(OrderhistoryContainer);