import React from 'react';
import {Link} from 'react-router-dom';
import { IMG_BASE_URL } from '../actions/types';
import Addtocart from '../components/Addtocart';
import {Currency} from '../utils/currency';

export default ({post}) => {
const shopdata={id:post.id,feature_image:post.feature_image,quantity:1,price:post.price,title:post.title,shipping:post.shipping,sizes:post.sizes,slug:post.slug};

return (
<div class="col-lg-4 col-md-6 col-6">
<div class="single-product mb-30">
<div class="product-img shop-product-list">

<Link to ={'/product/' +post.slug} className="prodimgsrc" >
<img src={IMG_BASE_URL+'products/'+post.feature_image} alt=""/>

</Link>
{/*<span className="newicon"><img src="/images/new-launch.jpg" alt="new" style={{'width':'34px','height':'78px'}} /></span>

<span class="descount-sticker">-10%</span>
<span class="sticker" style={{'display':'none'}}>New</span>*/}

</div>
<div class="product-content">
<h3><Link to ={'/product/' +post.slug} >{post.title}</Link></h3>
{/*<div class="rattingbtn"></div>*/}
<h4 class="price">

<span class="old">{Currency} {post.current_price} <span>{Currency} {post.mrp_price}</span></span>
<span style={{'display':'block','textAlign':'center'}}></span>
<span class="saveamount" style={{'display':'block','textAlign':'center'}}>	
Save {Currency} {parseFloat(post.mrp_price-post.current_price).toFixed(2)}	
</span>


<Addtocart produtdata={shopdata} />


</h4>
</div>
</div>

</div>







)
    }














