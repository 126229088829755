import React from 'react';
import {NavLink } from 'react-router-dom'
import { IMG_BASE_URL } from '../actions/types';


export default ({product}) => {

const image_array = product?product.ingredients_images.split(','):'';
  

return (

<div className="container">
  
  <div className="row">
                  <div className="col">
                  <div className="section-title mb-2 mb-xs-20 text-center">
<h2 className="leafbg1" style={{'fontWeight':'600','color':'#db9c28'}}>KEY INGREDIENTS</h2>
</div>

<p className="black-font">Every Ingredient we use is Vegan, Natural and Free of Chemicals. Here are a few:</p>

 </div>

 </div>

 <div className="row">


 {/* <div className="col-md-3 col-6 ingredient-img"><img src={IMG_BASE_URL+'Ingredients/'+image_array[0]} alt=""/></div> */}


 {image_array.map(function(item, i){
  
return (<div className="col-md-3 col-6 ingredient-img"><img src={IMG_BASE_URL+'Ingredients/'+item} alt=""/></div>);

 
})}
          

               
                     

 </div>

 <div className="col">
                <div className="single-product-description">
                <p style={{'textAlign':'center'}}><NavLink exact to="/ingredients" style={{'color':'#ef9f26'}}>Read More...</NavLink></p>
            </div>  
            </div> 

                  <div className="row">
                  <div className="col">
                  <div className="mb-2 mb-xs-20  black-font mt-4">
<h4 className="black-font"> COMPLETE LIST OF INGREDIENTS</h4>
<p> {product.ingredients_label}</p>
</div>
                  </div>
                  </div>


                 
  
  
            
          </div>



)
    }














