import React from 'react';

export default class Instagram extends React.Component {




 



    render(){
    return (
  <div className="product-section section pt-10 pt-lg-0 pt-md-70 pt-sm-60 pt-xs-50  pb-lg-40 pb-md-45 pb-sm-35 pb-xs-20 newsblog ">
  <div className="container">
                <div className="row">
                    <div className="col">
                        <div className=" mb-20 text-center intafeedblock">
                            <h2 className="leafbg  " style={{'fontWeight':'600','color':'#db9c28'}}>INSTAGRAM FEEDS</h2>
                            
                        </div>
                    </div>
                </div>              
            </div>

            <div className="col-12 pdphilo0 philoparent">
           <div className="instafeed">

           
<div class="elfsight-app-5a8966dc-fc2c-414a-b021-6d722bc65d56"></div>
         
            </div>


            </div>
            
        </div>
)
    }
}



