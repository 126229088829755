import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';

import { createStore, applyMiddleware,compose } from 'redux';
import thunk from 'redux-thunk';
import { Provider } from 'react-redux'
import rootReducer from './reducers';

import * as serviceWorker from './serviceWorker';
import jwtDecode from 'jwt-decode';

import { setCurrentUser } from './actions/auth';
import setAuthToken from './utils/setAuthToken';
const store = createStore(rootReducer, compose(
      applyMiddleware(thunk),
      window.devToolsExtension ? window.devToolsExtension() : f => f
    ));



if (localStorage.token) {
  setAuthToken(localStorage.token);
  store.dispatch(setCurrentUser(jwtDecode(localStorage.token)));
}



ReactDOM.render(<Provider store={store}>
        <App />
    </Provider>, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
