import React, {Component} from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';
import { fetchAllProducts } from '../actions/productdetail';
import { fetchRelatedpro } from '../actions/relatedproducts';
import Products from '../components/Products';
import { connect } from 'react-redux';
import ProductDetail from '../components/Product';
import Productingredients from '../components/Productingredients';
import Reviewsubmit from '../containers/ReviewSubmitContainer';
import { IMG_BASE_URL } from '../actions/types';
import { fetchAllproreview } from '../actions/reviewsubmit';
import { fetchAllTPosts } from '../actions/wishlist';
import parse from 'html-react-parser';
import Ourmission from '../components/Ourmission';

class ProductContainer extends Component {

	componentDidMount() {
        window.scrollTo(0, 0);
        let slug = this.props.match.params.slug; 
        this.props.dispatch(fetchAllProducts(slug));
        this.props.dispatch(fetchRelatedpro(slug));
        this.props.dispatch(fetchAllproreview(slug));
        this.props.dispatch(fetchAllTPosts());
    }


    componentDidUpdate(prevProps) {
        window.scrollTo(0, 0);
        let slug = this.props.match.params.slug;  
        if (slug!== prevProps.match.params.slug) {
            this.props.dispatch(fetchAllProducts(slug));
            this.props.dispatch(fetchRelatedpro(slug));
            this.props.dispatch(fetchAllproreview(slug));
        }
    }



render(){

const {ingredient}  = this.props.productdetail;
const {product}  = this.props.productdetail;
const {relatedproducts}  = this.props;
const {reviewsubmit}  = this.props;
const {wishlist}  = this.props;


if (!product) return <div>Loading...</div>;
return (

	<div id="main-wrapper">
    <Header/>
	<ProductDetail product = {product} ingredient={ingredient} reviewsubmit={reviewsubmit} wishlist={wishlist}
/>
    <br/>
    <br/>










 <div style={{'clear' : 'both', 'height' : '5px'}}></div>
                <div className="product-section section pt-100 pt-lg-80 pt-md-70 pt-sm-60 pt-xs-50 pb-65 pb-lg-45 pb-md-35 pb-sm-25 pb-xs-15 ingredient-sec">
                <div className="container">







                <div className="row">
<div className="col-12">                        


<nav style={{'textAlign':'center'}}>
  <div class="nav nav-tabs" id="nav-tab" role="tablist">
    <a class="nav-item nav-link active" id="nav-home-tab" data-toggle="tab" href="#nav-product-details" role="tab" aria-controls="nav-home" aria-selected="true">Product Details</a>
    {/*<a class="nav-item nav-link" id="nav-profile-tab" data-toggle="tab" href="#nav-why-it-work" role="tab" aria-controls="nav-profile" aria-selected="false">Why It Works</a>*/}
    <a class="nav-item nav-link" id="nav-contact-tab" data-toggle="tab" href="#nav-reviews" role="tab" aria-controls="nav-contact" aria-selected="false">Reviews</a>
    <a class="nav-item nav-link" id="nav-contact-tab" data-toggle="tab" href="#nav-plant-based-commitment" role="tab" aria-controls="nav-contact" aria-selected="false">Plant Based Commitment</a>
  </div>
</nav>
<div class="tab-content" id="nav-tabContent">
<div class="tab-pane fade show active black-font" id="nav-product-details" role="tabpanel" aria-labelledby="nav-home-tab">
  	
<div class="col-md-6 col-12 disblock rgtbdr pdalign">		
{parse(product[0].description )}	
</div>	
<div class="col-md-6 col-12 disblock pdalign ">		
<b>DIRECTIONS</b>
<p>{parse(product[0].howtouse )}</p>
<b>SOURCE</b>
<p>MADE IN INDIA</p>
</div>

</div>
<div class="tab-pane fade black-font" id="nav-why-it-work" role="tabpanel" aria-labelledby="nav-profile-tab">
  	
dsfsdfsdfsd
</div>
<div class="tab-pane fade" id="nav-reviews" role="tabpanel" aria-labelledby="nav-contact-tab">

<br/>
<Reviewsubmit slug={this.props.match.params.slug}/>
</div>


<div class="tab-pane fade" id="nav-plant-based-commitment" role="tabpanel" aria-labelledby="nav-contact-tab">

<p>Our products are not only very friendly and inert for one's skin but also are completely free from any chemicals or heavy metals. These toxins not only spoil the skin irreversibly but also fail to biodegrade thereby leaving a lasting damage on mother earth.</p>

<p>All our products, as the name suggests, come from the abundant natural environment that the Earth offers, making them effective, without side-effects, and sustainable. Most importantly, it allows them to provide the best for you!</p>

</div>
</div>
</div>
</div>

</div>



<div className="underline-product">


<Productingredients product = {product[0]} />
<Ourmission/>  

<div className="container">
  
<div className="row">
                <div className="col">
                    <div className="section-title">
                        <h2>COMPLETE THE SET</h2>
                    </div>
                </div>
                </div>
               

 <div className="row tf-element-carousel" >
                    {relatedproducts.map(post => {
                    return (
                      <Products post={post}  key={ post.id }/> 
                    );
                    })} 
                </div>
          
        </div>
        </div>

        </div>

    
<Footer/>
</div>



        )
    }
}

const mapStateToProps = state => {
    return {
        productdetail: state.productdetail,
        relatedproducts:state.relatedproducts,
        reviewsubmit:state.reviewsubmit,
        wishlist: state.wishlist.wishlist,
        auth: state.auth
    };
};

export default connect(
  mapStateToProps,
  null
)(ProductContainer);





