import React, { Component } from 'react';
import {Link} from 'react-router-dom';
import {connect} from 'react-redux';
import { deleteWishlist } from '../actions/wishlist';
import Addtocart from '../components/Addtocart';
import { IMG_BASE_URL } from '../actions/types';
import {Currency} from '../utils/currency';

class Wishlist extends Component {

	deletetoWishlist(post) {
      this.props.onDeletetowishlist(post);
    }

	render(){
	return (
		<tbody>
		 {this.props.wishlist.map((post) => {

const productdata={id:post.tof_product.Id,feature_image:post.tof_product.Image,quantity:1,price:post.tof_product.Price,title:post.tof_product.Name,shipping:post.tof_product.shipping,sizes:post.tof_product.sizes,slug:post.tof_product.slug};

		return (
	    <tr>			
			<td className="borderclr" style={{'borderRight':'none !importnat'}}>
				<div class="cart-img-order">
				<Link to ={'/product/' +post.tof_product.slug} ><img src={IMG_BASE_URL+'products/'+post.tof_product.Image}  alt=""/></Link>
				</div> 
			</td>
			<td class="pro-price borderclr" style={{'verticalAlign':'top','textAlign':'left'}}>
			<Link to ={'/product/' +post.tof_product.slug} ><span style={{'float':'left','paddingTop':'5px','display':'block','width':'100%','whiteSpace':'pre-wrap'}}>{post.tof_product.Name}</span></Link><br />
			<span style={{'float':'left','paddingTop':'5px','display':'block','width':'100%'}}>Price: {Currency} {parseFloat(post.tof_product.Price).toFixed(2)}</span><br />
			<span style={{'float':'left','paddingTop':'5px','display':'block','width':'100%'}} class="out-stock">Status: {post.tof_product.stock === 0 ? 'Out of Stock' : 'Availability' }</span>
			</td>			
			<td class="pro-addtocart borderclr">
				<Link to ='/cart'>
					<Addtocart produtdata={ productdata} />
				</Link>
			</td>
			<td class="pro-remove"><i class="fa fa-trash-o" style={{'cursor':'pointer'}} onClick={() => this.deletetoWishlist(post.wishlist_id)}></i></td>
		</tr>
		);
		})} 

	</tbody>
	)
}
}
const mapDispatchToProps = dispatch => {
  return {
      onDeletetowishlist: post => {
     dispatch(deleteWishlist(post));
    },
   };
};

const mapStateToProps = state => {
  return {
    wishlistdelete:state.wishlistdelete
 


  };
};
export default connect(
mapStateToProps,
mapDispatchToProps
)(Wishlist);
