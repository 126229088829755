import React, {Component} from 'react';
import { Link, Redirect } from 'react-router-dom';
import Header from '../components/Header';
import Footer from '../components/Footer';
import Alert from '../layout/Alert';


const initialState =  {
   
    email:'',
    password:'',
    emailError:'',
    passwordError:'',

};



export default class Login extends Component {

 state = initialState;


  handleInputChange = event => {
     const isCheckbox = event.target.type === "checkbox";
    this.setState({
      [event.target.name]: isCheckbox
        ? event.target.checked
        : event.target.value
    });
  };



   validate = () => {
    let emailError = "";
    let PasswordError = "";
  
    if (!this.state.email) {
       emailError = "Email cannot be blank.";

    }
    if(this.state.email){
       let lastAtPos = this.state.email.lastIndexOf('@');
       let lastDotPos = this.state.email.lastIndexOf('.');

       if (!(lastAtPos < lastDotPos && lastAtPos > 0 && this.state.email.indexOf('@@') === -1 && lastDotPos > 2 && (this.state.email.length - lastDotPos) > 2)) {
       emailError = "Please enter a valid email address (Ex: maxdoe@domain.com).";
      }
      }


    if (!this.state.password) {
       PasswordError = "Password cannot be blank.";

    }

  
    
    if (emailError || PasswordError ) {
      this.setState({emailError,PasswordError});
      return false;
    }

    return true;
  };







 handleSubmit = e => {
    e.preventDefault();
    const isValid = this.validate();
    
     if (isValid) {
     this.props.onLoginPost(this.state);
      this.setState(initialState);
    }
   
     

  };







    render(){
const { loading,isAuthenticated } = this.props.auth;



const referer = this.props.location.state?this.props.location.state.referer:'/dashboard';



  if (isAuthenticated) {


    return <Redirect to={referer} />;
  }
 
  



        return (
 <div id="main-wrapper">
 <Header/>
 <div className="login-register-section section pt-100 pt-lg-80 pt-md-70 pt-sm-60 pt-xs-50  pb-70 pb-lg-50 pb-md-40 pb-sm-30 pb-xs-20">
            <div className="container">
                <div className="row">
        <div className="col-md-12 col-sm-12">
        <h1 className="tt-title-subpages noborder" style={{'textAlign':'center'}}>ALREADY REGISTERED?</h1>
        </div>
        <div style={{'clear':'both','height':'1px'}}></div>
                        <div className="col-md-6 col-sm-6">
                            <div className="customer-login-register register-pt-0">
                               
                                <div className="register-form">
                                    <form action="#">
                                        <h3 className="tt-title">NEW CUSTOMER</h3>
              <p style={{'marginTop':'51px','marginBottom':'51px'}}>
                By creating an account with our store, you will be able to move through the checkout process faster, store multiple shipping addresses, view and track your orders in your account and more.
              </p>
              
              <div className="form-group">
              <Link  to="/sign-up" className="btn btn-top btn-border" >CREATE AN ACCOUNT</Link>
              </div>
                                        
                                    </form>
                                </div>
                            </div>
                        </div>
                      
                        <div className="col-md-6 col-sm-6">
                            <div className="customer-login-register">
                                <div className="form-login-title">
                                    <Alert  msgdivid="logindiv" /> 
                                    <Alert  msgdivid="successreset"/>   
                                </div>
                                <div className="login-form">
                                    <form onSubmit={ this.handleSubmit }>
                                        <div className="form-fild">
                                            <p><label>Email <span className="required">*</span></label></p>
                                            <input name="email"  type="text" onChange={ this.handleInputChange } value={ this.state.email }/>
                                            <div style={{ fontSize:14,color: "orange"}}>{this.state.emailError}</div>
                                        </div>
                                        <div className="form-fild" autoComplete="new-password" >
                                            <p><label>Password <span className="required">*</span></label></p>
                                            <input name="password" value={this.state.password }  autoComplete="off" type="password" onChange={ this.handleInputChange }  value={ this.state.password }/>
                                            <div style={{ fontSize:14,color: "orange"}}>{this.state.PasswordError}</div>
                                        </div>
                                        <div className="login-submit">
                                           

                                     <button class="btn"  disabled={loading}>{loading && <span>Sending...</span>}
                                        {!loading && <span>Login</span>}</button>



                                            
                                        </div>
                                        <div className="lost-password">
                                            <Link  exact to="/forgot">Lost your password?</Link>
                                           
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                        
                    </div>            
            </div>
        </div>
        <Footer/>
        </div>


        )
    }
}