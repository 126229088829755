import React, {Component} from 'react';
import Header from '../components/Header';
import Billing from '../components/Billing';
import Footer from '../components/Footer';
import { fetchAllCartpro } from '../actions/cart';
import { fetchAlladdress } from '../actions/address';
import {connect } from 'react-redux'

class BillingContainer extends Component {


	componentDidMount() {

this.props.dispatch(fetchAlladdress());
this.props.dispatch(fetchAllCartpro());
}


 componentDidUpdate(prevProps) {
        
        let slug = this.props.match.path; 
  
        if (slug!== prevProps.match.path) {
       this.props.dispatch(fetchAllCartpro());
          
        }
    }


    


    render(){


  const {cartproduct,address}  = this.props;

if (!cartproduct || !address) return <div>Loading...</div>;


        return (

        <div id="main-wrapper">
        <Header/>
        <Billing cartproduct={cartproduct} address={address} />
        <Footer/>
        </div>

     )
    }
}




const mapStateToProps = state => {
return {
cartproduct: state.cartproduct.cartproduct,
address: state.address.address

};
};


export default connect(
  mapStateToProps,
  null
)(BillingContainer);