import React, {Component} from 'react';

import Bloglist from '../components/Bloglist';

import { fetchbloghomepost } from '../actions/blog';
import { connect } from 'react-redux';






class BlogHomeContainer extends Component {

	componentDidMount() {
  
    this.props.dispatch(fetchbloghomepost());

}





    render(){

const {blog}  = this.props;

        return (

         <div className="blog-section section pt-10 pt-lg-0 pt-md-70 pt-sm-60 pt-xs-50  pb-lg-40 pb-md-45 pb-sm-35 pb-xs-20 newsblog ">
          
          <div className="container desktop-view">
 <div className="row">
                   
                    <div className="col">
                        <div className="section-title mb-20 mb-xs-20 text-center">
                            <h2 className="leafbg" style={{'fontWeight':'600','color':'#db9c28'}}>NEWS & BLOGS</h2>
                            
                        </div>
                    </div>
                   
                </div>

 <div className="row ">
   {blog.slice(0, 3).map((post, index) => {
     return (
     <Bloglist post={ post }  key={ post.id } />
      );
      })}
</div>
</div>

        
<div className="container mobile-view">
 <div className="row">
    <div className="col">
                        <div className="section-title mb-20 mb-xs-20 text-center">
                            <h2 className="leafbg" style={{'fontWeight':'600','color':'#db9c28'}}>NEWS & BLOGS</h2>
                            
                        </div>
                    </div>
                   
                </div>

 <div className="row ">
   {blog.slice(0, 1).map((post, index) => {
     return (
     <Bloglist post={ post }  key={ post.id } />
      );
      })}
</div>
</div>




        </div>



     )
    }
}






const mapStateToProps = state => {
  return {
    blog: state.blog
  };
};


export default connect(
  mapStateToProps,
  null
)(BlogHomeContainer);