import React, {Component} from 'react';
import Header from '../components/Header';
import Slider from '../components/Slider';
//import Sliderfooter from '../components/Sliderfooter';
//import Homeabout from '../components/Homeabout';
//import Philosophy from '../components/Philosophy';
import Ourmission from '../components/Ourmission';
//import Oursolutions from './OursolutionsContainer';
//import Testimonial from './TestimonialContainer';
import OurSolutionsCarousel from './OurSolutionsCarouselContainer';
import FeaturedContainer from './FeaturedContainer';
import NewLauchContainer from './NewLauchContainer';
import Instagram from '../components/Instagram';
import Bloghome from './BlogHomeContainer';
import Footer from '../components/Footer';
import {Helmet} from "react-helmet";

export default class HomeContainer extends Component {

  componentDidMount() {
    window.scrollTo(0, 0);
}


    render(){
        return (

        <div id="main-wrapper">
        <Helmet>
        <meta charSet="utf-8" />
        <title>Best Organic Shampoo For Hair | Best Natural Shampoo For Hair | Vegan Products</title>
        <meta name="description" content="Buy the best organic shampoo for hair.Our products contain natural and organic ingredients which is the reason that we produce best natural shampoo for hair at organic forest." />
        <meta name="Keywords" content="Best Organic shampoo for hair,Best Natural Shampoo For Hair"/>
        </Helmet>
       
        <Header/>
        <Slider/>
        <NewLauchContainer/>
        <OurSolutionsCarousel/>
        <FeaturedContainer/>
        <Ourmission/>
        
        <Bloghome/>
        <Instagram/>
        
        <Footer/>
        </div>

     )
    }
}