import React, { Component } from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import {connect} from 'react-redux';
import { deleteAddress } from '../actions/address';

class AddressEdit extends Component {
    
    state = {
        modal: false,
        fade: true
    };
    


  toggle = (id,name,address,city,state,zip,phone) => {
    this.setState({
        modal: !this.state.modal,
        id: id,
        name:name,
        address:address,
        city:city,
        state:state,
        zip:zip,
        phone:phone,
    });
  };

 deletetoAddress(post) {
    this.props.onDeletetoaddress(post);
      this.setState({
        modal: !this.state.modal,
 
});
    }
  render() {
    return (

    <div className="mailAddressContainer">
     {this.props.address.map((post) =>
        <address className="col-md-13 col-sm-6 col-xs-12 addhgt" style={{'border':'1px solid #ccc','padding':'0px','display':'inline-block','marginRight':'5px','verticalAlign':'top'}}>
        <h4 style={{'textAlign':'center','color':'#ffffff','background':'#014131','padding':'10px'}}>Address </h4>
         <div style={{'padding':'10px','marginBottom':'10px'}}>
                <p class="entab"><strong>{post.name}</strong></p>
                <p class="addlinehgt entab">{post.address} <br/>
                {post.city}, {post.state} {post.zip}</p>
                <p class="entab">Mobile: {post.phone}</p>
                <div className="addremove" modal={this.state.modal}>
                <i className="fa fa-trash-o" style={{cursor: 'pointer'}} onClick={e =>
                this.toggle(post.id,post.name,post.address,post.city, post.state, post.zip,post.phone)}></i>
               </div>
         </div>
    </address>)}


        <Modal isOpen={this.state.modal} toggle={this.toggle}
        className="modalpop">
            <ModalHeader style={{'border-bottom':'0px'}} toggle={this.toggle}><h3>Confirm Deletion</h3></ModalHeader>
                <ModalBody style={{'line-height': '0px'}}>
                    <div style={{'padding':'10px','marginBottom':'10px'}}>
                        <p class="entab"><strong>{this.state.name}</strong></p>
                        <p class="addlinehgt entab">{this.state.address} <br/>
                        {this.state.city}, {this.state.state} {this.state.zip}</p>
                        <p class="entab">Mobile: {this.state.phone}</p>
                    </div>
                </ModalBody>
            <ModalFooter>
                <Button color="primary" onClick={() =>this.deletetoAddress(this.state.id)}>Delete</Button>
                <Button color="secondary" onClick={this.toggle}>Cancel</Button>
            </ModalFooter>

        </Modal>



      </div>
    );
  }
}
const mapDispatchToProps = dispatch => {
    return {
        onDeletetoaddress: post => {
        dispatch(deleteAddress(post));
    },
   
  };
};

const mapStateToProps = state => {
  return {
    addressdelete:state.addressdelete,
  };
};

export default connect(
mapStateToProps,
mapDispatchToProps
)(AddressEdit);