import React from 'react';
import SlickSlider from "react-slick";
import {NavLink } from 'react-router-dom'

export default class Slider extends React.Component {
 
 render(){
  const settings = {
      dots: true,
      infinite: true,
      //cssEase: 'ease-out',
      //cssEase: 'cubic-bezier(0.600, -0.280, 0.735, 0.045)',
      //touchThreshold: 100,
      speed:1000,
      autoplaySpeed:6500,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: true,
      fade:true,
      pauseOnHover:true,
      draggable:true,

    };

return (

<div className="hero-section section position-relative slidersection " >
<SlickSlider {...settings} className="tabaki" style={{'margin':'0px !important'}}>






<NavLink exact to="/solution/plant-based-biotin" className="sliderimagelink">
<div style={{'margin': '0px !important'}}>
<div className="hero-item bg-image" style={{'marginLeft':'-10px !important'}}>
<img src="/images/hero/slider5.jpg" className="sldimage desktopslide" alt="Product"/>
<img src="/images/hero/slider5mob.jpg?v=1" className="sldimage mobileslide" alt="Product"/>
</div>
</div>
</NavLink>

<NavLink exact to="/solution/turmeric-and-bamboo-charcoal" className="sliderimagelink">
<div style={{'margin': '0px !important'}}>
<div className="hero-item bg-image" style={{'marginLeft':'-10px !important'}}>
<img src="/images/hero/slider6.jpg" className="sldimage desktopslide" alt="Product"/>
<img src="/images/hero/slider6mob.jpg?v=1" className="sldimage mobileslide" alt="Product"/>
</div>
</div>
</NavLink>

<NavLink exact to="/product/anti-dandruff-hair-mask" className="sliderimagelink">
<div style={{'margin': '0px !important'}}>
<div className="hero-item bg-image" style={{'marginLeft':'-10px !important'}}>
<img src="/images/hero/slider7.jpg" className="sldimage desktopslide" alt="Product"/>
<img src="/images/hero/slider7mob.jpg?v=1" className="sldimage mobileslide" alt="Product"/>
</div>
</div>
</NavLink>






<NavLink exact to="/solution/anti-dandruff" className="sliderimagelink">
<div style={{'margin': '0px !important'}}>
<div className="hero-item bg-image" style={{'marginLeft':'-10px !important'}}>
<img src="/images/hero/slider2-1.jpg" className="sldimage desktopslide" alt="Product"/>
<img src="/images/hero/slider2mob-1.jpg?v=1" className="sldimage mobileslide" alt="Product"/>

</div>
</div>
</NavLink>








</SlickSlider>

</div>
)
}
}



