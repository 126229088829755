import React, {Component} from 'react';
import {Link} from 'react-router-dom';
export default class Aboutus extends Component {


leftNavScroll = (fromtop)  => (e) => {
e.preventDefault();
window.scrollTo(0, fromtop);

};




render(){
return (
<div>
<div>
<div className="page-banner-section section bg-gray banner-section" style={{'padding':'10px 0 20px'}}>
<div className="container">
<div className="row">
<div className="col">
<div className="about-us-banner-bottom-frame">
<h3>Our Story</h3>
<h3></h3>
<h4 style={{'color': '#404041', 'textAlign': 'center'}}><b>A melange of 'natural' and 'new-wave' of healthy living, starting with your wellness products</b></h4>
<p>The Organic Forest is a proud outcome of our resolve and passion of healthy lifestyle for our loved ones.  The learnings along this road of health, healing and a holistic lifestyle were truly revelatory for both us and those around us, because what started out as a journey of working towards simple well-being, eventually became a passion much larger than what we thought it would be and we took it upon ourselves to ensure to make a difference in this world laden with pollutants that’s threatening our very existence.</p>
<p><Link to ='/our-story' class="descount-sticker_btn">Read more</Link></p>
</div>
</div>
</div>
</div>
</div>
<div className="my-account-section section pt-100 pt-lg-80 pt-md-70 pt-sm-60 pt-xs-50 pb-100 pb-lg-80 pb-md-70 pb-sm-60 pb-xs-50">
<div className="container">
<div className="row">



<a href="#" onClick={this.leftNavScroll(300)} className="abouttablft col-lg-3 col-sm-12 col-12"   > The Fresh <br/>Experience</a>
<a href="#" onClick={this.leftNavScroll(900)} className="abouttablft col-lg-3 col-sm-12 col-12"  > The Ethical <br/>Process</a>
<a href="#" onClick={this.leftNavScroll(1350)} className="abouttablft col-lg-3 col-sm-12 col-12" > Environment <br/>Sustainability</a>
<a href="#" onClick={this.leftNavScroll(1790)}className="abouttablft col-lg-3 col-sm-12 col-12"  > Cruelty-free <br/>Beauty</a>

</div>


<div  id="myaccountContentaa">


<div className="row mt-30">

<div className="tab-pane11" id="freshexperience">
<div className="myaccount-contentabb">


<div class="about-title  mb-xs-20 text-center">
<h3 className="heading" style={{'color': '#404041', 'textAlign': 'center'}}>The Fresh Experience</h3>  
</div>





<div className="float-left col-lg-6 col-sm-12 col-12 mt-20">
<p>We combine this ancestral wisdom of the mother earth with the latest green tribune technology developed with a team of leading cosmetic scientists and formulators to create our raw, fresh, organic skincare range of products.</p>
<p>Years and years of scientifically done research have gone into sourcing our plants from their optimum growing conditions around the world to attain the most desirable, targeted and magical results for dry skin. We only source carefully grown, therapeutic grade, organic botanical ingredients when they are at their freshest and most effective.</p>
<p>When you choose to buy any of our products, you feel the richness of its ingredients, in its most naked and unprocessed form. Because, fresh is all about what goes into the making.</p>
</div>
<div className="float-right col-lg-6 col-sm-12 col-12 mt-20">
<p><img src="/images/about1.jpg" alt="The Fresh Experience"/></p>
</div>
</div>
</div>
</div>

<div className="row mt-30">

<div className="tab-paneab" id="ethicalprocess" role="tabpanel">
<div className="myaccount-contentabb">
<div class="about-title  mb-xs-20 text-center">
<h3 className="heading" style={{'color': '#404041', 'textAlign': 'center'}}>The Ethical Process</h3>  
</div>

<div className="float-left col-lg-6 col-sm-12 col-12 mt-20">
<img src="/images/about2.jpg" alt="The Ethical Process" />
</div>
<div className="float-right col-lg-6 col-sm-12 col-12 mt-20">
<p>We follow highest grade of standards in our of process to provide our customers the best experience. From Careful selection of high grade organic ingredients across the world to validated safety and stability against international norms like EWG, Safe Cosmetics USA  and PETA. Our products are then clinically tested by an expert dermatologist to ensure they are safe to use on skin.</p>
</div>

</div>
</div>
</div>

<div className="row mt-30">
<div className="tab-paneab" id="environment" role="tabpanel">
<div className="myaccount-contentabb">
<div class="about-title  mb-xs-20 text-center">
<h3 className="heading" style={{'color': '#404041', 'textAlign': 'center'}}>Environment Sustainability</h3>  
</div>
<div className="float-left col-lg-6 col-sm-12 col-12 mt-20">
<p>Environment Sustainability in not only our core value but it’s a commitment. Our formulation is all natural plant based vegan which has lesser impact on environment in terms of lesser carbon footprint in comparison to existing products in market. We us packaging that’s 99% recyclable. We are making consistent effort to reduce water consumption by 20 percent in our manufacturing processes.</p>
</div>
<div className="float-right col-lg-6 col-sm-12 col-12 mt-20">
<p><img src="/images/about3.jpg" alt="Environment Sustainability"/></p>
</div>
</div>
</div>
</div>

<div className="row mt-30">
<div className="tab-paneab" id="crueltyfree" role="tabpanel">
<div className="myaccount-contentabb">

<div class="about-title  mb-xs-20 text-center">
<h3 className="heading" style={{'color': '#404041', 'textAlign': 'center'}}>Cruelty-free Beauty</h3>  
</div>
<div className="float-left col-lg-6 col-sm-12 col-12 mt-20">
<p><img src="/images/about4.jpg" alt="Cruelty Free Beauty"/></p>
</div>
<div className="float-right col-lg-6 col-sm-12 col-12 mt-20">
<p>We are certified by PETA as our products are cruelty free and not tested on animals. Apart from this, we don’t use any ingredients that are derived from animals. Our products DO NOT CONTAIN - <span style={{'color':'#548235'}}>Carcinogen, Formaldehyde, Heavy metals, Endocrine disruptors, GMOs, PEGs, Synthetic Fragrances & Phenoxyethanol</span> which are widely used in many beauty and cosmetic products globally.</p>
</div>
</div>
</div>
</div>


</div>  
</div>           
</div>
</div>
</div>
)
}
}