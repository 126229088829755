import React from 'react';



export default class Ourmission extends React.Component {
render(){
return (
<div className="product-section section pt-md-65 pt-sm-60 pt-xs-45 pb-20 pb-lg-40 pb-md-40 pb-sm-30 pb-xs-20">
<div className="col-12 pdphilo0 philoparent">                       
<div className="col-md-12  "> 
<div className="section-title mb-20 mb-xs-20 text-center">
<h2 className="leafbg" style={{'fontWeight':'600','color':'#db9c28'}}>JOIN OUR MISSION TO CLEAN LIVING</h2>
</div>
</div>
<div className="container"> 

<div className="row">
<div className="col-md-2 text-center col-6" > 
<p><img src="/images/icons-3.jpg" alt="" title=""/>
</p>
<p>SAFE & BIODEGRADABLE</p>
</div>

<div className="col-md-2 text-center col-6"> 
<p><img src="/images/icons-1.jpg" alt="" title=""/>
</p>
<p>5 Star REVIEWS</p>
</div>
<div className="col-md-2 text-center col-6"> 
<p><img src="/images/icons-4.jpg" alt="" title=""/>
</p>
<p>MADE BY PHD SCIENTISTS</p>
</div>
<div className="col-md-2 text-center col-6"> 
<p><img src="/images/icons-5.jpg" alt="" title=""/>
</p>
<p>PLANT BASED INGREDIENTS</p>
</div>
<div className="col-md-2 text-center col-6"> 
<p><img src="/images/icons-2.jpg" alt="" title=""/>
</p>
<p>VEGETARIAN & SAFE</p>
</div>
<div className="col-md-2 text-center col-6"> 
<p><img src="/images/icons-6.jpg" alt="" title=""/>
</p>
<p>NO PARABENS, SULFATES & MORE</p>
</div>
</div>


<div className="row">
<div className="col-md-2 text-center col-6"> 
<p><img src="/images/logo-1.jpg" alt=""  style={{'width':'90%','marginTop':'14px'}}/>
</p>
</div>

<div className="col-md-2 text-center col-6"> 
<p><img src="/images/logo-2.jpg" alt="" title=""/>
</p>

</div>
<div className="col-md-2 text-center col-6"> 
<p><img src="/images/logo-3.jpg" alt="" title=""/>
</p>

</div>
<div className="col-md-2 text-center col-6"> 
<p><img src="/images/logo-4.jpg" alt="" title=""/>
</p>

</div>
<div className="col-md-2 text-center col-6"> 
<p><img src="/images/logo-5.jpg" alt="" title=""/>
</p>

</div>
<div className="col-md-2 text-center col-6"> 
<p><img src="/images/logo-6.jpg" alt="" title=""/>
</p>

</div>
</div>



</div>
</div>
</div>  
)
}
}



