import React, {Component} from 'react';
import Header from '../components/Header';
import { fetchAllTPosts } from '../actions/ingredient';
import { connect } from 'react-redux';
import Ingredients from '../components/Ingredients';
import Footer from '../components/Footer';
import {Helmet} from "react-helmet";


class IngredientsContainer extends Component {

	componentDidMount() {
    window.scrollTo(0, 0);
    this.props.dispatch(fetchAllTPosts());

}



componentDidUpdate(prevProps) {

let path=this.props.match.path.replace("/","");


}






    render(){
 const {ingredient}  = this.props;
    return (

<div id="main-wrapper">
<Helmet>
<meta charSet="utf-8" />
<title>Our Ingredients | The Organic Forest </title>
<meta name="description" content="The Organic Forest products are Certified Vegan & Plant-Based, 100% Vegetarian, like Organic Argan Oil, Aloe Vera, TeaTree, Apple Cider Vinegar that are a superfood for your hair." />
<meta name="Keywords" content="Our Ingredients"/>
</Helmet>

<Header/>
<div> 
<div className="shop-section section pt-30 pt-lg-30 pt-md-60 pt-sm-50 pt-xs-45 pb-70 pb-lg-50 pb-md-40 pb-sm-60 pb-xs-50 ouringredientspage">
<div className="container">
<div className="row">
<div className="col-lg-12 order-lg-2 order-1">
<div className="row">
<div className="col-12">
<div className="shop-banner-title">
<h2 className="leafbg" style={{'fontWeight':'600','color':'#db9c28','textAlign':'center'}}>Our Ingredients</h2>
</div>
</div>
</div>

<div className="row">
<div className="col-12">
<div className="shop-product">
<div id="myTabContent-2" className="tab-content">
<div id="grid" className="tab-pane fade active show">
<div className="product-grid-view">
<div className="row">

 {ingredient.map(post => {
    return (
    <div className="col-lg-3 col-md-6 col-sm-6">
      <div className="single-product mb-30">
    <Ingredients post={ post }  key={ post.id } />
      </div>
    </div>
          );
    })}


</div>
</div>
</div>
</div>
</div>

</div>
</div>
</div>
</div>      

</div>

</div>
</div>


<Footer/>
</div>



        )
    }
}

const mapStateToProps = state => {
  return {
    ingredient: state.ingredient
  };
};


export default connect(
  mapStateToProps,
  null
)(IngredientsContainer);