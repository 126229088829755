import React from 'react';
import IngredientRelatedProduct from '../components/Products';

import { IMG_BASE_URL } from '../actions/types';


export default ({ ingredientInfo,productInfo }) => {



return (

<div> 

<div class="shop-section section pt-60 pt-lg-30 pt-md-60 pt-sm-50 pt-xs-45 pb-70 pb-lg-50 pb-md-40 pb-sm-60 pb-xs-50">
<div class="container">
<div class="row">
<div class="col-lg-12">
<div class="shop-banner-title">
<h2 class="leafbg" style={{'fontWeight':'600','color':'#db9c28','textAlign':'center'}}>{ingredientInfo.name}</h2>

</div>
</div>
<div class="col-lg-12 ingrecenter"><img src={IMG_BASE_URL+'ingredients/'+ingredientInfo.image} alt="" align="center"/></div>

</div>
</div>
<div class="ingreboxbg mt-1">
<div class="container">
<div class="row">
<div class="col-lg-12">
<div class="shop-banner-title">
<h2 style={{'fontWeight':'600','color':'#3F5736','textAlign':'center'}}>About {ingredientInfo.name}</h2>

</div>
</div>
<div class="col-lg-12 ingrecenter">
<p style={{'fontWeight':'400','color':'#3F5736','textAlign':'center'}}>
{ingredientInfo.description}
</p>
</div>

</div>
</div>
</div>



<div class="container">
<div class="row">
<div class="col-lg-12 order-lg-2 order-1">

<div class="row">
<div class="col-12">
<div class="shop-banner-title">
<h2 class="leafbg" style={{'fontWeight':'600','color':'#db9c28','textAlign':'center'}}>Products with same ingredients</h2>
</div>
</div>
</div>

<div class="row">
{productInfo.map(post => {
return (
<IngredientRelatedProduct post={post}  key={ post.id }/>
);
})}




</div>
</div>

</div>
</div>






</div>



</div>





)
    }














