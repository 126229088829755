import React, {Component} from 'react';
import Header from '../components/Header';
import IngredientProduct from '../components/IngredientProduct';
import Footer from '../components/Footer';
import { fetchAllIRelated } from '../actions/ingredientproduct';
import { connect } from 'react-redux';
import {Helmet} from "react-helmet";
import {trimString} from '../utils/trimString';

class IngredientsProductContainer extends Component {


	componentDidMount() {
    window.scrollTo(0, 0);
    let slug = this.props.match.params.slug; 
    this.props.dispatch(fetchAllIRelated(slug));


}

render(){
const {ingredientInfo}  = this.props.fetchingredientrelated;
const {productInfo}  = this.props.fetchingredientrelated;

console.log(ingredientInfo);




if (!ingredientInfo) return <div>Loading...</div>;
        return (
        <div id="main-wrapper">
        <Helmet>
<meta charSet="utf-8" />
<title>Ingredient {ingredientInfo.name}</title>
<meta name="description" content={trimString(ingredientInfo.description,120)} />
<meta name="Keywords" content={ingredientInfo.name}/>
</Helmet>

        <Header/>
        <IngredientProduct ingredientInfo = {ingredientInfo} productInfo={productInfo} />
        
        <Footer/>
        </div>



        )
    }





}



const mapStateToProps = state => {
    return {
        fetchingredientrelated: state.fetchingredientrelated
    };
};

export default connect(
  mapStateToProps,
  null
)(IngredientsProductContainer);


