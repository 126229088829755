import React, {Component} from 'react';
import { createaddtoAddress } from '../actions/address';
import Alert from '../layout/Alert';
import { connect } from 'react-redux';
import Header from '../components/Header';
import Footer from '../components/Footer';
import Accountsidebar from '../components/Accountsidebar';
import { Link,NavLink } from 'react-router-dom'

const initialState =  {
        name: '',
        phone:'',
        email: '',
        address: '',
        city: '',
        zip: '',
        state:'' ,
        nameErrors:'',
        phoneErrors:'',
        emailErrors:'',
        addressErrors:'',
        cityErrors:'',
        zipErrors:'',
        stateErrors:'',
        status:1,
        addressbook:1
      };

  const phoneRegex = /^\d{10}$/;
  const zipRegex = /^\d{6}$/;

 class NewAddress extends Component {
    

  state = initialState;


  handleInputChange = event => {
     const isCheckbox = event.target.type === "checkbox";
    this.setState({
      [event.target.name]: isCheckbox
        ? event.target.checked
        : event.target.value
    });
  };
   
 validate = () => {
    let nameErrors = "";
    let phoneErrors = "";
    let emailErrors = "";
    let addressErrors = "";
    let cityErrors = "";
    let zipErrors = "";
    let stateErrors = "";


   if (!this.state.name) {
       nameErrors = "Name cannot be blank";
    }
   if (!this.state.phone) {
       phoneErrors = "Phone Number cannot be blank";
    }
    else if (!phoneRegex.test(this.state.phone)) {
      phoneErrors = "Invalid Phone Number.Please enter 10 digit Phone Number";
    } 

   if (!this.state.address) {
       addressErrors = "Address cannot be blank";
    }
   if (!this.state.city) {
       cityErrors = "City cannot be blank";
    }
    if (!this.state.zip) {
       zipErrors = "Pincode cannot be blank";
    }
    else if (!zipRegex.test(this.state.zip)) {
      zipErrors = "Invalid Pin Code.Please enter 6 digit Pin Code";
    } 
    if (!this.state.state) {
       stateErrors = "State cannot be blank";
    }
   

   if (nameErrors || phoneErrors  || addressErrors || cityErrors || zipErrors || stateErrors) {
      this.setState({nameErrors,phoneErrors,addressErrors,cityErrors,zipErrors,stateErrors });
      return false;
    }
return true;
  };

handleSubmit = e => {
    e.preventDefault();
   const isValid = this.validate();
    if (isValid) {
      this.props.onAddAddress(this.state);

      console.log(this.state);
      
      this.setState(initialState);

  }
};
componentDidMount() {
    console.log(this.props);
} 



    render(){


  return (

 <div id="main-wrapper">
        <Header/>
          <div className="page-banner-section section bg-gray" style={{'padding': '10px 0 20px'}}>
              <div className="container">
                  <div className="row">
                      <div className="col">
                          <div className="page-banner text-center">
                              <h1><a href="/dashboard">Dashboard</a></h1>
                              <ul className="page-breadcrumb">
                                  <li><Link exact to="/" >Home</Link></li>
                                  <li>Add Address</li>
                              </ul>
                          </div>                        
                      </div>
                  </div>
              </div>
          </div>     
        <div class="my-account-section section pt-100 pt-lg-80 pt-md-70 pt-sm-60 pt-xs-50  pb-100 pb-lg-80 pb-md-70 pb-sm-60 pb-xs-50">
            <div class="container">
                <div class="row">
                    <div class="col-12">
                        <div class="row">  
                            <Accountsidebar/>   
                             <div className="col-lg-9 col-12">
                                <div className="tab-content" id="myaccountContent">
                                  <div className="tab-pane fade show active" id="address-edit" role="tabpanel">
                                    <div className="myaccount-contentab" >
                                      <h3>Address Book</h3>
                                      <div style={{'textAlign':'right'}}>
                                         <NavLink exact to="/addressbook" > <button className="place-order btn btn-lg btn-round">Back to  Address Book</button></NavLink>
                                      </div>
                                  
                                <form onSubmit={ this.handleSubmit }>
                                <div className="col-md-12 col-lg-8" style={{'paddingLeft':'0px'}}><Alert/> </div>
                                   
                    <div className="tt-login-form">
                       <div className="row justify-content-left">
                        <div className="col-md-12 col-lg-8">         
                            <div className="form-default">
                                  <div id="resp" className="col-md-6 col-md-offset-3">
                                  </div>
                                  <div style={{'clear':'both','height':'2px'}}></div>
                                    <div className="form-group">
                          <label>Full Name*</label>
                          <input type="text" placeholder="Full Name" name="name" onChange={ this.handleInputChange } className="form-control" value={ this.state.name }/>
                          <div style={{ fontSize:14,color: "red"}}>{this.state.nameErrors}</div>

                      </div>
                
                        <div className="form-group">
                          <label>Phone no*</label>
                          <input type="text" placeholder="Phone number" name="phone" maxlength="10"  onChange={ this.handleInputChange } className="form-control" value={ this.state.phone }/>
                           <div style={{ fontSize:14,color: "red"}}>{this.state.phoneErrors}</div>

                      </div>
                   <div className="form-group">
                          <label>Address*</label>
                          <input type="text" placeholder="Address line 1" name="address" onChange={ this.handleInputChange } className="form-control" value={ this.state.address }/>                                            
                          <div style={{ fontSize:14,color: "red"}}>{this.state.addressErrors}</div>

                      </div>
                      <div className="form-group">
                          <label>Town/City*</label>
                          <input type="text" placeholder="Town/City" name="city" onChange={ this.handleInputChange } className="form-control" value={ this.state.city}/>
                           <div style={{ fontSize:14,color: "red"}}>{this.state.cityErrors}</div>
                      </div>
                      <div className="form-group ">
                          <label>State*</label>
                          <select class="form-control" name="state"  onChange={ this.handleInputChange } value={ this.state.state }>
<option value="">--- Select State ---</option>  
                                                                        
<option value="Andaman &amp; Nicobar Islands">Andaman &amp; Nicobar Islands</option>

<option value="Andhra Pradesh">Andhra Pradesh</option>

<option value="Arunachal Pradesh">Arunachal Pradesh</option>

<option value="Assam">Assam</option>

<option value="Bihar">Bihar</option>

<option value="Chandigarh">Chandigarh</option>

<option value="Chhattisgarh">Chhattisgarh</option>

<option value="Dadra &amp; Nagar Haveli">Dadra &amp; Nagar Haveli</option>

<option value="Daman and Diu">Daman and Diu</option>

<option value="Delhi">Delhi</option>

<option value="Goa">Goa</option>

<option value="Gujarat">Gujarat</option>

<option value="Haryana">Haryana</option>

<option value="Himachal Pradesh">Himachal Pradesh</option>

<option value="Jammu &amp; Kashmir">Jammu &amp; Kashmir</option>

<option value="Jharkhand">Jharkhand</option>

<option value="Karnataka">Karnataka</option>

<option value="Kerala">Kerala</option>

<option value="Lakshadweep">Lakshadweep</option>

<option value="Ladakh">Ladakh</option>

<option value="Madhya Pradesh">Madhya Pradesh</option>

<option value="Maharashtra">Maharashtra</option>

<option value="Manipur">Manipur</option>

<option value="Meghalaya">Meghalaya</option>

<option value="Mizoram">Mizoram</option>

<option value="Nagaland">Nagaland</option>

<option value="Odisha">Odisha</option>

<option value="Puducherry">Puducherry</option>

<option value="Punjab">Punjab</option>

<option value="Rajasthan">Rajasthan</option>

<option value="Sikkim">Sikkim</option>

<option value="Tamil Nadu">Tamil Nadu</option>

<option value="Telangana">Telangana</option>

<option value="Tripura">Tripura</option>

<option value="Uttarakhand">Uttarakhand</option>

<option value="Uttar Pradesh" selected="">Uttar Pradesh</option>

<option value="West Bengal">West Bengal</option>

</select>

                          <div style={{ fontSize:14,color: "red"}}>{this.state.stateErrors}</div>

                      </div>

                        <div className="form-group">
                          <label>Zip Code*</label>
                          <input type="text" name="zip" maxlength="6" placeholder="Zip Code"onChange={ this.handleInputChange } className="form-control" value={ this.state.zip }/>
                          <div style={{ fontSize:14,color: "red"}}>{this.state.zipErrors}</div>

                      </div>
                         <div className="form-group">
                      <input name="status"  className="form-control" type="hidden" onChange={ this.handleInputChange } value={ this.state.status }/>                                      
                        </div>  
                      <div class="col-12 mb-30">
                      
                       <button class="place-order btn btn-lg btn-round">Add Address</button>
                      </div>
                    </div>
                  </div>
               </div>
            </div>
          </form>
                                     </div>
                                  </div>
                              </div>
                          </div>


    
                         </div>
                    </div> 
                </div>           
            </div>
        </div>
    <Footer/>
</div>


    )
}
}


const mapDispatchToProps = dispatch => {
  return {
    onAddAddress: post => {

      dispatch(createaddtoAddress(post));
       
    }

  };
};

const mapStateToProps = state => {
  return {
    address: state.address
  };
};

export default connect(
  null,
  mapDispatchToProps
)(NewAddress);




