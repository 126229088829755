import React,{ Component }  from 'react';
import {getCookieValue,setCookie} from './utils/cookie';
import './App.css';
import Home from './containers/HomeContainer';
import Ourstory from './containers/OurstoryContainer';
import Blog from './containers/BlogContainer';
import BlogDetail from './containers/BlogDetailContainer';
import OurSolutions from './containers/SolutionsContainer';
import Category from './containers/CategoryContainer';
// import SubCategory from './containers/SubCategoryContainer';
import Shop from './containers/ShopContainer';
import Login from './containers/LoginContainer';
import Signup from './containers/SignupContainer';
import Faq from './containers/FaqContainer';
import Termcondition from './containers/TandcContainer';
import Privacypolicy from './containers/PrivacypolicyContainer';
import Contact from './containers/ContactContainer';
import Ingredients from './containers/IngredientsContainer';
import AddressEdit from './containers/AddressEditContainer';
import AccountInfo from './containers/AccountInfoContainer';
import Dashboard from './containers/DashboardContainer';
import Wishlist from './containers/WishlistContainer';
import Shipping from './containers/ShippingContainer';
import Aboutus from './containers/AboutusContainer';
import Changepassword from './containers/ChangepasswordContainer';
import Checkout from './containers/CheckoutContainer';
import Orderhistory from './containers/OrderhistoryContainer';
import IngredientsProduct from './containers/IngredientsProductContainer';
import Philosophy from './containers/PhilosophyContainer';
import Search from './containers/SearchContainer';
import Tag from './containers/TagContainer';
import Product from './containers/ProductContainer';
import PrivateRoute from './components/PrivateRoute'
import Review from './containers/ReviewContainer';
import OrderDetail from './containers/OrderdetailContainer';
import NodataFound from './components/NodataFound'
import NewAddress from './components/AddressAdd';
import Addressupdate from './components/Addressupdate';
import AllReview from './components/AllReview';
import Billing from './containers/BillingContainer';
import Forgot from './containers/ForgotpasswordContainer';
import Reset from './containers/ResetContainer';
import Orderstatus from './containers/OrderstatusContainer';
import Cart from './containers/CartContainer';
import Orderconfirm from './containers/OrderconfirmContainer';

import { Route, BrowserRouter, Switch } from 'react-router-dom';
let cartid=getCookieValue('cartid');
if(!cartid)
{
setCookie('cartid',Math.random().toString().replace('0.', '')); 
}




class App extends Component {




 render() {
    return (
      
   <BrowserRouter>
   <Switch>
   <Route exact path='/' component={Home}/>
   <Route path='/solutions' component={OurSolutions} />
   <Route path='/shop' component={Shop} />
   <Route path='/aboutus' component={Aboutus} />
   <Route path='/philosophy' component={Philosophy} />
   <Route path='/blog' component={Blog} />
   <Route path='/blog-detail/:slug' component={BlogDetail} />
   <Route path='/our-story' component={Ourstory} />
   <Route path='/login' component={Login} />
   <Route path='/sign-up' component={Signup} />
   <Route path='/faq' component={Faq} />
   <Route path='/terms-conditions' component={Termcondition} />
   <Route path='/privacy-policy' component={Privacypolicy} />
   <Route path='/contactus' component={Contact} />
   <Route path='/ingredients' component={Ingredients} />
   <PrivateRoute path='/cancelorderproduct/:id' component={Orderstatus} />
   <PrivateRoute path='/returnorderproduct/:id' component={Orderstatus} />
   <PrivateRoute path='/add/addressbook' component={NewAddress} />
   <PrivateRoute path='/edit/addressbook' component={Addressupdate} />
   <PrivateRoute path='/addressbook' component={AddressEdit} />
   <PrivateRoute path='/account-info' component={AccountInfo} />
   <PrivateRoute path='/dashboard' component={Dashboard} />
   <PrivateRoute path='/review' component={Review} />
   <PrivateRoute path='/wishlist' component={Wishlist} />
   <PrivateRoute path='/shipping' component={Shipping} />
   <PrivateRoute path='/billing' component={Billing} />
   <PrivateRoute path='/order-confirm/:ordernumber' component={Orderconfirm} />

   <PrivateRoute path='/change-password' component={Changepassword} />
   <Route path='/allreviews/:slug' component={AllReview} />

   <Route path='/checkout' component={Checkout} />
   <PrivateRoute path='/order-history' component={Orderhistory} />
   <Route path='/ingredient-product/:slug' component={IngredientsProduct} />
   <Route path='/search/:key' component={Search} />
   <Route exact path='/solution/:mslug' component={Category} />
   {/*<Route exact path='/solution/:mslug/:slug' component={SubCategory} />*/}
   <Route path='/tag' component={Tag} />
   <Route path='/product/:slug' component={Product} />
   <Route path='/cart' component={Cart} />
   <PrivateRoute path='/your-order/:ordernumber' component={OrderDetail} />
   <Route path='/forgot' component={Forgot}/>
   <Route path='/resetpassword/:data' component={Reset} />

   <Route path="*" component={NodataFound} />
   </Switch>
  
   </BrowserRouter>


  );
  }


  
}

export default App;
