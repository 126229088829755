import React, {useState,useEffect} from 'react';
import { Link,NavLink,withRouter } from 'react-router-dom'
import { logout } from '../actions/auth';
import { connect,useDispatch,useSelector } from 'react-redux';
import CartDropdown from '../components/CartDropdown';
import CartPopup from '../components/CartPopup';
import { fetchAllCartpro } from '../actions/cart';
import { fetchAllTPosts } from '../actions/wishlist';

const Header = (props,logout) => {

const dispatch = useDispatch();
const wishlist = useSelector((state) => state.wishlist.wishlist);

useEffect(() => {
const id=props.auth.user.id
if(id && props.auth){
dispatch(fetchAllTPosts(id));
}

}, []); 





const [isOpen, setIsOpen] = useState(false);

const [panel, setPanel] = useState(false);

const [name, setName] = useState("");
const [visible, setVisible] = useState(false);

const [visible1, setVisible1] = useState(false);
const [visible2, setVisible2] = useState(false);
const [visible3, setVisible3] = useState(false);
const [visible4, setVisible4] = useState(false);
const [visible5, setVisible5] = useState(false);
const [visible6, setVisible6] = useState(false);

const [icon, setIcon] = useState("");


let style = {};
let style1 = {'fontSize':'0'};
let style2 = {'fontSize':'0'};
let style3 = {'fontSize':'0'};
let style4 = {'fontSize':'0'};
let style5 = {'fontSize':'0'};
let style6 = {'fontSize':'0'};

function showhideMe(){
setVisible(!visible);
}

function showhideMe1(){

setVisible1(!visible1);
}

function showhideMe2(){

setVisible2(!visible2);
}

function showhideMe3(){

setVisible3(!visible3);
}
function showhideMe4(){

setVisible4(!visible4);
}
function showhideMe5(){

setVisible5(!visible5);
}

function showhideMe6(){

setVisible6(!visible6);
}

if (!visible) style.display = "none";
if (visible) style.display = "block";

if (!visible1) {
style1.display = "none";
};

if (visible1) { 


style1.display = "block";

} 

if (!visible2) {
style2.display = "none";
};

if (visible2) { 


style2.display = "block";

} 
if (!visible3) {
style3.display = "none";
};

if (visible3) { 


style3.display = "block";

} 
if (!visible4) {
style4.display = "none";
};

if (visible4) { 


style4.display = "block";

} 

if (!visible5) {
style5.display = "none";
};

if (visible5) { 


style5.display = "block";

} 

if (!visible6) {
style6.display = "none";
};

if (visible6) { 


style6.display = "block";

} 







const toggle = () => setIsOpen(!isOpen);

const toggleSearch = () => setPanel(!panel);

const handleSubmit = e => {
e.preventDefault();
let ser=`${name}`;
if(ser === ''){
ser = 'none';
}
if(ser.includes('anti') || ser.includes('Anti') || ser.includes('ANTI') ){
ser= ser.replace(' ', '-');
} ;
window.location.href = '/search/'+ser;
};



const {isAuthenticated, loading}=props.auth;
const {cartproduct}=props;
let hearticon;

if (wishlist && wishlist.length>=1) {
hearticon = 'fa fa-heart fa-2x';
} else 
{
hearticon = 'fa fa-heart-o';
}
const cartcount=cartproduct?cartproduct.reduce((a,c) => (a+c.quantity), 0):0;



return (

<header class="header header-transparent header-sticky" id="mainheader">

<div class="header-bottom menu-right bg-dark">
<div class="container-fluid pl-75 pr-75 pl-lg-15 pr-lg-15 pl-md-15 pr-md-15 pl-sm-15 pr-sm-15 pl-xs-15 pr-xs-15">
<div class="row align-items-center menuheight">

<div class="col-lg-2 col-md-6 col-sm-6 col-6 order-lg-1 order-md-1 order-1">
<div class="logo">
<NavLink exact to="/" ><img src="/images/logo.png" alt="logo"/></NavLink>
</div>
</div>

<div class="col-lg-6 col-md-6 col-sm-6 col-12 order-lg-2 order-md-2 order-2 d-flex justify-content-center mobile-headersec">
<nav class="main-menu color-white hideinmobile" style={{'display':'block'}}>
<ul>
<li><NavLink exact to="/solutions" className="active" activeClassName="activenav">Ranges</NavLink>
<ul class="mega-menu four-column">

<li><NavLink exact to="/solution/anti-dandruff">Anti Dandruff</NavLink>
<ul>
<li><NavLink exact to="/product/anti-dandruff-shampoo">Shampoo</NavLink></li>
<li><NavLink exact to="/product/anti-dandruff-oil">Oil</NavLink></li>

</ul>
</li>
<li><NavLink exact to="/solution/anti-hairfall">Anti Hairfall</NavLink>
<ul>
<li><NavLink exact to="/product/anti-hairfall-shampoo">Shampoo</NavLink></li>
<li><NavLink exact to="/product/anti-hairfall-oil">Oil</NavLink></li>


</ul>
</li>

<li><NavLink exact to="/solution/plant-based-biotin" className="item-link">Plant-Based Biotin</NavLink>
<ul>
<li><NavLink exact to="/product/10000-mcg-plant-based-biotin-shampoo">Shampoo</NavLink></li>


</ul>
</li>








</ul>  


</li>
<li><NavLink exact to="/ingredients" className="active" activeClassName="activenav">Ingredients</NavLink></li>
<li><NavLink exact to="/shop" className="active" activeClassName="activenav">Shop</NavLink></li>
<li><NavLink exact to="/aboutus" className="active" activeClassName="activenav">About Us</NavLink></li>
<li> <NavLink exact to="/blog" className="active" activeClassName="activenav">Blog</NavLink></li>
<li><NavLink exact to="/contactus" className="active"  activeClassName="activenav">Contact Us</NavLink></li>
</ul>   
</nav>
</div>

<div class="col-lg-4 col-md-7 col-sm-6 col-12 order-lg-3 order-md-3 order-3 d-flex justify-content-end topmenu_srch justify-head marginmenu">



<div class="footer-social social_align">
<a href="https://www.facebook.com/The-Organic-Forest-113438947068234" target={"_blank"}  className="facebook"><i className="fa fa-facebook" ></i></a>
{/* <a href="https://twitter.com/forest_organic" target={"_blank"} className="twitter"><i className="fa fa-twitter" ></i></a>                                 */}
<a href="https://www.instagram.com/the_organic_forest/" target={"_blank"} className="linkedin"><i className="fa fa-instagram" ></i></a>
<a href="https://wa.me/918595749256/?text=I have a few questions. Can you help?" target={"_blank"} className="linkedin"><i className="fa fa-whatsapp"></i></a>

</div>




<div className="header-search">
{!panel &&
<div>  
<button className="header-search-toggle color-white"  onClick={toggleSearch}><i className="fa fa-search"></i></button></div>
}
{panel &&

<div>
<button class="header-search-toggle color-white open" onClick={toggleSearch}><i class="fa fa-times"></i></button>
<div class="header-search-form" style={{'display': 'block'}}>


<form onSubmit={handleSubmit }>
<input type="text" placeholder="Type and hit enter" placeholder="Search Product Here" name="search" value={name}  onChange={e => setName(e.target.value)}/>
<button  type="submit"><i class="fa fa-search"></i></button>

</form>
</div>
</div>
}

</div>



<CartDropdown cartcount={cartcount}/>
<CartPopup/>



{!loading && (
isAuthenticated?<div class="footer-social social_align" style={{'paddingLeft':'10px'}}>
<button className="header-search-toggle color-white likeicon"><NavLink exact to="/wishlist" style={{margin: '3px 1px 0 0'}}><i className={hearticon}></i></NavLink></button>
<NavLink exact to="/dashboard"  className="facebook facebook1" style={{'color':'#db9c28','marginTop':'6px','cursor':'pointer'}}>My Account</NavLink>
{/*<div style={{'width':'200px','height':'auto','position':'absolute','top':'60px','right':'20px','zIndex':'9999','border':'1px solid #eeeeee','background':'#ffffff','borderRadius':'0px','boxShadow':'0px 0px 15px -5px rgba(0, 0, 0, 0.6)'}}>
<a onClick={() => props.logout()} style={{'margin':'0px 0px 0 0','borderBottom':'1px solid #eeeeee','background':'#ffffff','borderRadius':'0px'}}><span>Logout</span></a>
</div>*/}
</div>:<div class="footer-social social_align signintxt signintxt1" style={{'paddingLeft':'10px'}}>&nbsp;&nbsp;
<NavLink exact to="/login" className="facebook" style={{'color':'#db9c28','marginTop':'6px'}}>Sign In </NavLink>
<NavLink exact to="/sign-up" className="facebook" style={{'color':'#db9c28','marginTop':'6px'}}>Sign Up</NavLink>
</div>


)}








</div>

</div>




<div class="row mobilemenubymanish">
<div class="col-12 d-flex d-lg-none d-block">

<div class="mobile-menu mean-container">
<div class="mean-bar"><a href={void(0)} class="meanmenu-reveal" onClick={showhideMe} style={{'background':'','color':'','right':'0','left':'auto'}}><span class="menu-bar"></span></a>

<nav class="mean-nav">

<ul style={style}>
<li><NavLink exact to="/solutions" className="active">Ranges</NavLink>
<ul class="mega-menu three-column dsadasd" style={style1}>
{/*   <li>
<a href="/#" class="item-link">DAILY USE</a>
<ul style={style2}>
<li><a href="compare.html">Shampoo</a></li>
<li><a href="cart.html">Conditioner</a></li>
</ul>
<a class="mean-expand" href={void(0)} style={{'fontSize':'0'}}  onClick={showhideMe2}>{!visible2?'+':'-'}</a></li>*/}
<li>
<NavLink exact to="/solution/anti-dandruff">Anti Dandruff</NavLink>

<ul style={style3}>
<li><NavLink exact to="/product/anti-dandruff-shampoo">Shampoo</NavLink></li>
<li><NavLink exact to="/product/anti-dandruff-oil">Oil</NavLink></li>
</ul><a class="mean-expand" href={void(0)} style={{'fontSize':'0'}} onClick={showhideMe3}>{!visible3?'+':'-'}</a>
</li>

<li><NavLink exact to="/solution/anti-hairfall">Anti Hairfall</NavLink>

<ul style={style4}>
<li><NavLink exact to="/product/anti-hairfall-shampoo">Shampoo</NavLink></li>
<li><NavLink exact to="/product/anti-hairfall-oil">Oil</NavLink></li>
</ul><a class="mean-expand" href={void(0)} style={{'fontSize':'0'}} onClick={showhideMe4}>{!visible4?'+':'-'}</a>
</li>


<li><a href="/#">Plant-Based Biotin</a>

<ul style={style5}>
<li><NavLink exact to="/product/10000-mcg-plant-based-biotin-shampoo">Shampoo</NavLink></li>



</ul><a class="mean-expand" href={void(0)} style={{'fontSize':'0'}} onClick={showhideMe5}>{!visible5?'+':'-'}</a>
</li>





</ul>
<a class="mean-expand" href={void(0)} style={{'fontSize':'0'}} onClick={showhideMe1}>{!visible1?'+':'-'}</a></li>
<li><NavLink exact to="/ingredients" className="active">Ingredients</NavLink></li>
<li><NavLink exact to="/shop" className="active">Shop</NavLink></li>
<li><NavLink exact to="/aboutus" className="active">About Us</NavLink></li>
<li> <NavLink exact to="/blog" className="active">Blog</NavLink></li>
<li><NavLink exact to="/contactus" className="active" style=
{{'borderBottom': '1px solid #ffffff'}}>Contact Us</NavLink>
</li>

</ul>
</nav>
</div>
</div>
</div>
</div>






</div>
</div>

</header>




)


}


const mapStateToProps = state => ({
auth: state.auth,
cartproduct: state.cartproduct.cartproduct,
});


export default connect(
mapStateToProps,
{ logout }
)(Header);










