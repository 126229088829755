import React, {Component} from 'react';
//import ReCAPTCHA from 'react-google-recaptcha'
import Alert from '../layout/Alert';

const initialState =  {
    email:'',
    emailError:'',
    rtoken:'',
  };



export default class NewSubscribe extends Component {
    

  
 constructor(props) {
    super(props);
    this.state = {
    email:'',
    emailError:'',
    rtoken:'',
    };
 
    //this._reCaptchaRef = React.createRef();
  }

  handleInputChange = event => {
     const isCheckbox = event.target.type === "checkbox";
    this.setState({
      [event.target.name]: isCheckbox
        ? event.target.checked
        : event.target.value
    });
  };

   validate = () => {
    let emailError = "";
  
    if (!this.state.email) {
       emailError = "Email cannot be blank.";

    }

   if(this.state.email){
      let lastAtPos = this.state.email.lastIndexOf('@');
      let lastDotPos = this.state.email.lastIndexOf('.');

      if (!(lastAtPos < lastDotPos && lastAtPos > 0 && this.state.email.indexOf('@@') === -1 && lastDotPos > 2 && (this.state.email.length - lastDotPos) > 2)) {
       emailError = "Email is not valid.";
      }
    }



    
    if (emailError) {
      this.setState({emailError });
      return false;
    }

    return true;
  };



handleSubmit = e => {
    e.preventDefault();
    const isValid = this.validate();
   // this._reCaptchaRef.current.execute();


    if (isValid) {

      
     
      this.props.onAddSubscribe(this.state);
      this.setState(initialState);
    }
};

componentDidMount() {
//this._reCaptchaRef.current.execute();

} 


redoCaptcha() {
  this._reCaptchaRef.current.reset();
  this._reCaptchaRef.current.execute();
}


handleChange = (value) => {


if (this._reCaptchaRef) {
 this.setState({rtoken:value});
 this._reCaptchaRef.current.reset();

      }



  
  };



render(){


const { loading } = this.props.subscribe;


return (
         <div>
           <Alert  msgdivid="subscription"/>
            <div className="section-title mb-20 mb-xs-20 text-center">
            <h2 className="leafbg" style={{'fontWeight':'600','color':'#db9c28'}}>Join Our Family</h2>
         </div>

       
             <div className="newsletter-content text-center">                  
					<p>Subscribe to get special offers, free giveaways, and once-in-a-lifetime deals.</p>
						<div className="subscription-form ">
							<form  id="mc-form2" className="mc-form" onSubmit={ this.handleSubmit }>
              <div className="form-group col-md-10 offset-md-1">
              <input type="text" placeholder="Your email address" name="email"
              onChange={ this.handleInputChange }
              onBlur={this.validate}
              value={ this.state.email } className="form-control subscribeform " />
             <div style={{ fontSize:14,color: "orange"}}>
                {this.state.emailError}
              </div>
              </div>
          
          <div className="form-group ">
            <button type="submit" className="btn btn-primary" disabled={loading}>{loading && <span>Sending...</span>}
                                        {!loading && <span>Subscribe</span>}</button>
          </div>

        {/*



          <ReCAPTCHA
  ref={this._reCaptchaRef} size="invisible"
    sitekey="6LfuGNkZAAAAADta57Vcyk4T7Hl_l-mwpQKaPco4"
    onChange={this.handleChange}
    onExpired={this.redoCaptcha}
  />*/}


	               </form> 
			</div>
									
		</div>
	</div>
        
        )
    }
}



									
											
									