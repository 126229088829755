import React, { Component } from 'react'
import { IMG_BASE_URL } from '../actions/types';
import {Currency} from '../utils/currency';
import {Link} from 'react-router-dom';
import Cartcoupon from './Cartcoupon';
import Alert from '../layout/Alert';

export default class Checkoutcart extends Component {
render(){
const {cartproduct}=this.props;
    return(
  <div class="col-lg-5">
                                <div class="row">                                  
                                    <div class="col-12 mb-5">
                                        <h4 class="checkout-title text-center">ORDER SUMMARY</h4>
                                        <div class="checkout-cart-total" style={{'height':'370px','overflow-y':'auto'}}>
                                            <h4>Product <span>Total</span></h4>
                                            <ul>
                                                
                                {cartproduct.map((post) => 
                                    <div>
                                                <li>
                                                <div>
                                                <div className="shipng-price">{Currency}{parseFloat(post.tof_product.Price*post.quantity).toFixed(2)}</div>
                                                <span style={{'width':'130px','height':'130px','float':'left','margin-right':'0px'}} className="checkcartimg">
  <Link to ={'/product/' +post.slug} className="prodimgsrc" ><img src={IMG_BASE_URL+'products/'+post.feature_image} alt=""/></Link>
                                                </span>
                                                <Link to ={'/product/' +post.slug} className="prodimgsrc" ><span className="shipng-cont">{post.title}</span></Link><br /> 
                                                <span className="shipng-cont">Qty: {post.quantity}</span><br /> 
                                               {post.sizes=== null ||post.sizes==='undefined'||post.sizes===''?'': <span className="shipng-cont">Size: {post.sizes}</span>}

</div>
                                                </li>
                                                <div style={{'clear':'both','height':'10px'}}></div></div>
                                              )} 
                                                
                                            </ul>
                                           
                                        </div>
                                    </div>
                                  
                             

                                </div>

                                <div className="cart-summary" style={{'max-width':'100%'}}>


                            <div className="cart-summary-wrap">
                            <h4 style={{'marginBottom':'10px'}} className="text-center">Payment Summary</h4>
                            <p style={{borderBottom:'1px solid #ffffff'}}></p>
                            <p>Sub Total <span>{Currency}{parseFloat(cartproduct.reduce((a, c) => (a +c.tof_product.Price * c.quantity), 0)).toFixed(2)}</span></p>
                            <p>Shipping Fee <span>{Currency}{parseFloat(cartproduct.reduce((a, c) => (a + c.shipping * 1), 0)).toFixed(2)}</span></p>

{( cartproduct[0].coupon_id!==0 && cartproduct[0].coupon_type==='percentage') &&
<div>
<p>Coupon Applied ({cartproduct[0].coupon_code}) <span>{Currency} {parseFloat(cartproduct.reduce((a, c) => (a + c.tof_product.Price * c.quantity + c.shipping * 1), 0)*cartproduct[0].coupon_value/100).toFixed(2)}</span>

</p>
<h2>Grand Total  <span>{Currency} {parseFloat(cartproduct.reduce((a, c) => (a + c.tof_product.Price * c.quantity + c.shipping * 1), 0) - cartproduct.reduce((a, c) => (a + c.tof_product.Price * c.quantity + c.shipping * 1), 0)*cartproduct[0].coupon_value/100).toFixed(2)}</span></h2>
</div>
}

{( cartproduct[0].coupon_id!==0 && cartproduct[0].coupon_type==='fixed') &&
<div>
<p>Coupon Applied ({cartproduct[0].coupon_code}) <span> {Currency} {parseFloat(cartproduct[0].coupon_value).toFixed(2)}</span></p>
<h2>Grand Total  <span>{Currency} {parseFloat(cartproduct.reduce((a, c) => (a + c.tof_product.Price * c.quantity + c.shipping * 1), 0) - cartproduct[0].coupon_value).toFixed(2)}</span></h2>
</div>
}

{( cartproduct[0].coupon_id===0) &&
<div>
<h2>Grand Total  <span> {Currency} {parseFloat(cartproduct.reduce((a, c) => (a + c.tof_product.Price * c.quantity + c.shipping * 1), 0)).toFixed(2)}</span></h2>
</div>
}





                            </div>
<Alert  msgdivid="couponalert"/>
                            <Cartcoupon cartproduct={cartproduct}/> 

  </div>


                            </div>                    

        )
   } 
}



