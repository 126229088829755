import React, {Component} from 'react';
import { Link } from 'react-router-dom'
import { logout } from '../actions/auth';
import { connect } from 'react-redux';

class Dashboard extends Component {
    render(){
      const {accountinfo}= this.props;
        return (
       <div>
        <div className="my-account-section section pt-100 pt-lg-40 pt-md-70 pt-sm-60 pt-xs-50  pb-100 pb-lg-80 pb-md-70 pb-sm-60 pb-xs-50">
            <div className="container">
                <div className="row">
            <div className="tt-block-title">
                <h2 className="tt-title">Hello <span  style={{'textTransform':'capitalize'}}>{accountinfo.name}</span> !</h2>
                
                <p>Welcome to your own space The Oraganic Forest ! Here you can check past orders, build your Wishlist with all your favourite pieces, manage account settings and keep up to date with latest trends.</p>
            </div>
            <div className="row tt-img-box-listing moblftspace">
                
                <div className="col-sm-6 col-md-4 col-12 dashdata">
                 

                     <Link exact to="/account-info" className="tt-img-box">   
                      <div className="col-sm-3 col-md-3 col-3">
                      <img src="/images/dashboard/icon-1.png" alt=""/>
                        </div>
                        <div className="col-sm-9 col-md-9 col-9">
                            <div className="tt-title" style={{'textAlign':'left'}}>
                                <h6 style={{'letterSpacing':'0em','paddingBottom':'0px'}}>MY INFO</h6>
                                <p style={{'margin':'0px'}}>Edit your information and preference</p>
                            </div>
                        </div>
                    </Link>  
                </div>
                <div className="col-sm-6 col-md-4 col-12 dashdata">  
                <Link exact to="/order-history" className="tt-img-box">    
                 
                        <div className="col-sm-3 col-md-3 col-3">
                        <img src="/images/dashboard/icon-4.png" alt=""/>
                        </div>
                         <div className="col-sm-9 col-md-9 col-9">
                        <div className="tt-title" style={{'textAlign':'left'}}>
                        <h6 style={{'letterSpacing':'0em','paddingBottom':'0px'}}>ORDER HISTORY</h6>
                        <p style={{'margin':'0px'}}>View, Track and buy things again</p>
                    </div></div>
                   </Link>  
                </div>
              
                <div className="col-sm-6 col-md-4 col-12 dashdata">                 
                   <Link exact to="/addressbook" className="tt-img-box"> 
                        <div className="col-sm-3 col-md-3 col-3">
                        <img src="/images/dashboard/icon-6.png" alt=""/>
                            </div>
                             <div className="col-sm-9 col-md-9 col-9">
                            <div className="tt-title" style={{'textAlign':'left'}}>
                        <h6 style={{'letterSpacing':'0em','paddingBottom':'0px'}}>ADDRESS BOOK</h6>
                            <p style={{'margin':'0px'}}>Edit or Add Delivery and Billing Addresses.</p>
                        </div></div>
                    </Link>  
                </div>
   
               <div className="col-sm-6 col-md-4 col-12 dashdata">
                    <Link exact to="/review" className="tt-img-box">
                        <div className="col-sm-3 col-md-3 col-3">
                        <img src="/images/dashboard/icon-2.png" alt=""/>
                            </div>
                             <div className="col-sm-9 col-md-9 col-9">
                            <div className="tt-title" style={{'textAlign':'left'}}>
                        <h6 style={{'letterSpacing':'0em','paddingBottom':'0px'}}>MY REVIEWS</h6>
                            <p style={{'margin':'0px'}}>Edit your information, login details and preference</p>
                        </div></div>
                    </Link>  
                </div>              
                    
                <div className="col-sm-6 col-md-4 col-12 dashdata">
                    <Link exact to="/wishlist" className="tt-img-box">
                        <div className="col-sm-3 col-md-3 col-3">
                        <img src="/images/dashboard/icon-5.png" alt=""/>
                        </div>
                        <div className="col-sm-9 col-md-9 col-9">
                        <div className="tt-title" style={{'textAlign':'left'}}>
                            <h6 style={{'letterSpacing':'0em','paddingBottom':'0px'}}>MY WISHLIST</h6>
                            <p style={{'margin':'0px'}}>Checkout all the fab things you saved!<br />&nbsp;</p>
                         </div>
                     </div>
                    </Link>  
                </div>
              
              



           <div class="col-sm-6 col-md-4 col-12 dashdata"> 
                   <Link exact to="/change-password" className="tt-img-box">
                        <div class="col-sm-3 col-md-3 col-3"> 
                        <img src="/images/dashboard/icon-7.png" alt=""/>
                        </div>
                         <div class="col-sm-9 col-md-9 col-9">
                        <div class="tt-title" style={{'textAlign':'left'}}>
                        <h6 style={{'letterSpacing':'0em','paddingBottom':'0px'}}>CHANGE PASSWORD</h6>
                        <p style={{'margin':'0px'}}>Change your Password</p>
                    </div></div>
                    </Link>  
                </div>

                <div className="col-sm-6 col-md-4 col-12 dashdata">                 
                    <Link  onClick={() => this.props.logout()}  className="tt-img-box">
                        <div className="col-sm-3 col-md-3 col-3">
                        <img src="/images/dashboard/icon-3.png" alt=""/>
                            </div>
                             <div className="col-sm-9 col-md-9 col-9">
                            <div className="tt-title" style={{'textAlign':'left'}}>
                        <h6 style={{'letterSpacing':'0em','paddingBottom':'0px'}}>SIGN OUT</h6>
                          
                        </div></div>
                    </Link>  
                </div>     



                
                
         
    </div>
   
                    
                </div> 
            </div>           
        </div>
        </div>


        )
    }
}


const mapStateToProps = state => ({
  auth: state.auth
});


export default connect(
  mapStateToProps,
  { logout }
)(Dashboard);

