import React, {Component} from 'react';
import { connect } from 'react-redux';
import { fetchAllblogcomment } from '../actions/blogcomment';
import Addblogcomment from '../components/Blogcommentsubmit';
import {dateFormat} from '../utils/dateFormat';

//import Blogcommentlist from '../components/Blogcommentlisting';

 class BlogCommentContainer extends Component {

	componentDidMount() {
		window.scrollTo(0, 0);
		let slug = this.props.slug;
		this.props.dispatch(fetchAllblogcomment(slug));
	}

	componentDidUpdate(prevProps) {
		let slug =this.props.slug;
		if (slug!== prevProps.slug) {
		this.props.dispatch(fetchAllblogcomment(slug));
		}
	}
	
    render(){
		const {blogcomment}  = this.props;
		
if (!blogcomment) return <div>Loading...</div>;

		return (
		
	<div class="blog-section section  pt-sm-50 pt-xs-40 pb-100 pb-lg-80 pb-md-70 pb-sm-60 pb-xs-50">
            <div class="container">
                <div class="row">               
							<div class="col-lg-6">
							{blogcomment===''?  
                   			<div class="comments-area  mt-10 ">
								<h3>Comments:</h3>
                        		<h4>Be the first to Comment.</h4></div>: 
								<div class="comments-area  mt-10 ">
									<h3> comments</h3>
                                    {blogcomment.map(comment => ( 
										<ol class="commentlist">
											<li> 										
											<div class="single-comment">
												<div class="comment-avatar img-full">
													<img src="assets/images/icons/author.png" alt=""/>
												</div>
												<div class="comment-info">
													<a href="/#">{comment.name}</a>
												<span class="date">{dateFormat(comment.date)}</span>
													<p>{comment.comment}</p>
												</div>
											</div>						
											</li>
										</ol>))}					  
								</div>}
							</div>	
							<div class="col-lg-6"> 	
								<div class="comment-box mt-10">
									<h3>Leave a Comment</h3>
									<Addblogcomment slug={this.props.slug}/>
								</div>
                    </div>
                </div>
            </div>
        </div>	
		)
	}
}



const mapStateToProps = state => {
	return {
		blogcomment: state.blogcomment,
	};
}

export default connect(
  mapStateToProps,
)(BlogCommentContainer);

