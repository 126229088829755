import React, { Component } from 'react'
import {connect} from 'react-redux';
import { createstatus } from '../actions/orderstatus';
import Alert from '../layout/Alert';

const initialState =  {
    enumstatus: '',
    reason:'',
    reasonErrors:'',
  id:''
  };
  
class Orderstatus extends Component {
  state = initialState; 
  
  validate = () => {
    let reasonErrors = "";
    if (!this.state.reason) {
      reasonErrors = "Please select Return reason";
    }
    if (reasonErrors) {
      this.setState({reasonErrors});
      return false;
    }
    return true;
  };
 


  constructor(props) {
    super(props);
    this.state = {
      reason: "",
      enumstatus:"Returned",
      id:this.props.id
    };
    this.handleDropdownChange = this.handleDropdownChange.bind(this);
  }

    handleInputChange = event => {
    const isCheckbox = event.target.type === "checkbox";
    this.setState({
      [event.target.name]: isCheckbox ? event.target.checked: event.target.value
    });
  }; 
  
    handleDropdownChange(e) {
    this.setState({ reason: e.target.value });
  }
  
    handleSubmit = e => {
    e.preventDefault();
    const isValid = this.validate();
    if (isValid) {  
    
    
      this.props.Statuscheck(this.state);
      this.setState(initialState);
    }
  };
  
  

   render(){
    return (
     <div className="col-md-4 col-sm-4 col-12" style={{'display':'inline-block','float':'left','paddingTop':'16px'}}>
       <Alert/>
 <span style={{'line-height':'18px','font-weight':'400','color':'#000000','font-size':'18px'}}>Reasons for Return:</span>

                  {this.props.orderstatus.map((item) => 
                       <div class="">
					  						 
                          <div class="login-submit" style={{'textAlign':'center'}}>
                              <select    class="form-control" id="dropdown" onChange={this.handleDropdownChange}>
                                 <option value="">Select Return Reason</option>
                                <option value="Bought by mistake">Bought by mistake</option>
                                <option value="Better price available">Better price available</option>
                                <option value="Performance or quality not adequate">Performance or quality not adequate</option>
                                <option value="Incompatible or not useful">Incompatible or not useful</option>
                                <option value="Product damaged, but shipping box OK">Product damaged, but shipping box OK</option>
                                <option value="Item arrived too late">Item arrived too late</option>
                                <option value="Missing parts or accessories">Missing parts or accessories</option>
                                <option value="Both product and shipping box damaged">Both product and shipping box damaged</option>
                                <option value="Wrong item was sent">Wrong item was sent</option>
                                <option value="Received extra item I didn’t buy">Received extra item I didn’t buy</option>
                                <option value="No longer needed">No longer needed</option>
                                <option value="Didn't approve purchase">Didn't approve purchase</option>
                                <option value="Inaccurate website description">Inaccurate website description</option>
                              </select>
                              <div style={{ fontSize:14,color: "red"}}>{this.state.reasonError}</div>

                              <br/>
                                <form action="/#" onSubmit={ this.handleSubmit }>
                               <input type="hidden" onChange={ this.handleInputChange } value={ this.state.reason }  name="reason"/>
                              <input type="hidden" onChange={ this.handleInputChange } value={this.state.enumstatus} name="status"/>

                                <div class="col-12 mb-30" style={{'paddingLeft':'0px'}}>
                                  <div class="login-submit" style={{'textAlign':'left'}}>                              
                               		 <button class="btn"><span>Save Changes</span></button>
                                  </div>
                                </div>
                      </form>
                       </div>
                </div>)} 

      </div>     
    )
    }
}

const mapDispatchToProps = dispatch => {
  return {
    Statuscheck: post => {
     dispatch(createstatus(post));
    }
  };
};

const mapStateToProps = state => {
  return {
    createstatus: state.createstatus
  };
};


export default connect(
mapStateToProps,
mapDispatchToProps
)(Orderstatus);







                
                                                  
                               
